import classes from "./LoginHead.module.scss";
import logo from '../assets/images/henry_logo.png';
function LoginHead() {
  return (
    <div>
      <div className={classes.loginLogoContainer}><img className={classes.loginLogo} src={logo}/></div>
      <title className={classes.loginTitle}>Login</title>
      <div>Ingrese e-mail y Password</div>
    </div>
  );
}

export default LoginHead;
