import React,{ useEffect, useState,useContext} from 'react';
import Layout from "../../components/Layout";
import classes from "../../crud/clients/NewClient.module.scss";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { 
  validate_int,
  alphabetic_with_space, 
  valid_len, 
  validate_email,
  validate_alphanumeric_spaces_symbols,
  validate_alphanumeric_spaces_more_symbols } from '../../utils/validator';
import Swal from 'sweetalert2';
import {changeClient} from "../../Services/ClientServices";
import { useNavigate } from 'react-router-dom';
                                                          // json data
import cities from  '../../assets/app_data/localidades.json'                                                          
import states from '../../assets/app_data/provincias.json'                                                          
import {useParams} from "react-router-dom";

import AuthContext from '../../Context/AuthContext';
import {StringToBinary, StringFromBinary} from "../../utils/Base64Utils"

function EditClient (){
  
  const context = useContext(AuthContext);
  const TIMEOT_SESSION_CLOSES = 3000;

  const {client} = useParams();
  var client_data = JSON.parse(StringFromBinary( client ));
  var client_surname = client_data.apellidonombre.substring(0,client_data.apellidonombre.indexOf(","));
  var client_name = client_data.apellidonombre.substring(client_data.apellidonombre.indexOf(",")+1,client_data.apellidonombre.length);
  console.log( 'name: ' +  client_name + ' - surname: ' + client_surname  );
  console.log( client_data )
  let local_bank_data = false;
  if( client_data.local_bank === '1' )local_bank_data = true;


  const navigate = useNavigate();
  const [error_name, setErrorName] = useState("");
  const [error_surname, setErrorSurName] = useState("");
  const [error_address, setErrorAddress] = useState("");
  const [error_phone, setErrorPhone] = useState("");
  const [error_document, setErrorDocument] = useState("");
  const [error_email, setErrorEmail] = useState("");
  const [error_cbu1, setErrorCBU1] = useState("");
  const [error_cbu2, setErrorCBU2] = useState("");
  const [error_city, setErrorCity] = useState("");
  const [error_state, setErrorState] = useState("");
  const [error_boss, setErrorBoss] = useState("");

  const [isChecked, setIsChecked] = useState(local_bank_data);
  const [isCheckedEdit, setIsCheckedEdit] = useState(false);

  const handleToggle = () => setIsChecked(!isChecked);
  const handleToggleEdit = () => {
    const value = !isCheckedEdit;
    setIsCheckedEdit( value );
    setDisable( !value );
  }

  if(!client_data.address)client_data.address='';
  if(!client_data.telefono)client_data.telefono='';
  if(!client_data.dni)client_data.dni=0;
  if(!client_data.email)client_data.email='';
  if(!client_data.CBU_1)client_data.CBU_1='';
  if(!client_data.CBU_2)client_data.CBU_2='';
  if(!client_data.state)client_data.state='';
  if(!client_data.boss)client_data.boss='';
  if(!client_data.city)client_data.city='';
  

  const [_name, setName] = useState(client_name.trimStart());
  const [_id, setID] = useState(client_data.id_cliente);
  const [_surname, setSurName] = useState(client_surname.trimStart());
  const [_address, setAddress] = useState(client_data.address);
  const [_phone, setPhone] = useState(client_data.telefono);
  const [_document, setDocument] = useState(client_data.dni);
  const [_email, setEmail] = useState(client_data.email);
  const [_cbu1, setCBU1] = useState(client_data.CBU_1);
  const [_cbu2, setCBU2] = useState(client_data.CBU_2);
  const [_city, setCity] = useState(client_data.city);
  const [_state, setState] = useState(client_data.state);
  

  console.log( _state )
  console.log( _city )

  const [_cities, setCities] = useState( buildCitiesList( client_data.state ));
  const [_states, setStates] = useState(states.provincias);
  const [_boss, setBoss] = useState(client_data.boss);

                                                      // disables fields and commands
  const [disable, setDisable] = useState(true);

  
  function validateData ( data, field ) {
    console.log( '-------' )
    console.log( data )
    console.log( field )
    var _error = '';
    
      switch( field ){
        case 'name':
          setName( data );
          if( !valid_len(data,3,20)) _error = "Longitud no válida";
          if( !alphabetic_with_space(data) ) _error = "Ingrese letras o espacios";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorName( _error );
          break;

        case 'surname':
          setSurName( data );
          if( !valid_len(data,3,20)) _error = "Longitud no válida";
          if( !alphabetic_with_space(data) ) _error = "Ingrese letras o espacios";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorSurName( _error );  
          break;

        case 'document':
          setDocument( data );
          if( !valid_len(data,6,10)) _error = "Longitud no válida";
          if( !validate_int(data) ) _error = "Ingrese números";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorDocument( _error );
          break;      

        case 'address':
          setAddress( data );
          if( !valid_len(data,2,50)) _error = "Longitud no válida";
          if( !validate_alphanumeric_spaces_more_symbols( data ))_error = "Existen caracteres no válidos";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorAddress( _error );        
          break;                          

        case 'phone':
          setPhone( data );
          if( !valid_len(data,5,20)) _error = "Longitud no válida";
          if( !validate_int(data) ) _error = "Ingrese números";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorPhone( _error );
          break;     


        
                                                                              //19-09-2023: email validation disabled

        case 'email':
          setEmail( data );
          /*
          if( !valid_len(data,5,128)) _error = "Longitud no válida";
          if( !validate_email(data) ) _error = "Email no válido";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorEmail( _error );        
          */
          break;     
        

        case 'cbu1':
          setCBU1( data );
          if( !valid_len(data,7,9)) _error = "Longitud no válida";
          if( !validate_int(data) ) _error = "Ingrese números";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorCBU1( _error );
          break;     

        case 'cbu2':
          setCBU2( data );
          if( !valid_len(data,13,15)) _error = "Longitud no válida";
          if( !validate_int(data) ) _error = "Ingrese números";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorCBU2( _error );
          break;
          
        case 'city' :
          setCity( data );
          if( !valid_len(data,2,50) || !validate_alphanumeric_spaces_more_symbols( data ) ) _error = "Ciudad no válida";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorCity( _error );        
          break;

        case 'state':
          setState( data );
          if( !valid_len(data,2,50) || !validate_alphanumeric_spaces_more_symbols( data )) _error = "Provincia no válida";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorState( _error );                
          break;

          case 'boss':
            setBoss( data );
            if( !valid_len(data,3,20)) _error = "Longitud no válida";
            if( !validate_alphanumeric_spaces_symbols( data ) ) _error = "Ingrese letras, números, () o espacios";
            if( data.length === 0 )_error = "Campo obligatorio";
            setErrorBoss( _error );
            break;        
      }
    
    console.log( _error )
    return _error;
  }

  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handleChange = event => {
    validateData(event.target.value, event.target.id)
  };


  /*****************************************************************************
   * rstClient: 
   *****************************************************************************/
  function rstClient(){
    const data = '';
    setName( data );
    setSurName( data );
    setDocument( data );
    setAddress( data );
    setPhone( data );
    setEmail( data );
    setCBU1( data );
    setCBU2( data );
    setCity( data );
    setState( data );
    setBoss( data );
    setCities( [] );
    setStates( states.provincias );
  }


  const handlerBtn_cancel = event =>{
    rstClient();
    navigate('/clients');
  }



  /*****************************************************************************
   * handleStateSelect: updates selected state and updates cities for that state 
   *****************************************************************************/  

  function buildCitiesList( name ){
    var stateCities = [];
    cities.localidades.forEach(element => {
      if( element.provincia.nombre === name ){
        stateCities.push( element );
      }
    });
    return stateCities;
  }

  

  const handleStateSelect = event =>{
    setState( event.target.value );
    setCity( '' );
    setCities( buildCitiesList( event.target.value ));
  }

  /*****************************************************************************
   * handleStateSelect: updates selected state and updates cities for that state 
   *****************************************************************************/  

  const handleCitySelect = event =>{
    setCity( event.target.value );
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }


  /*****************************************************************************
   * handlerBtn_newClient: 
   *****************************************************************************/

  const handlerBtn_newClient = event =>{
    
    if(
        validateData(_name,     'name' )     === '' &&
        validateData(_surname,  'surname' )  === '' &&
        validateData(_address,  'address' )  === '' &&
        validateData(_phone,    'phone' )    === '' &&
        validateData(_document, 'document' ) === '' &&
                                                            // disables mail validation
        //validateData(_email,    'email' )    === '' &&

        validateData(_cbu1,     'cbu1' )     === '' &&
        validateData(_cbu2,     'cbu2' )     === '' &&
        validateData(_state,    'state' )    === '' &&
        validateData(_city,     'city' )     === '' &&        
        validateData(_boss,     'boss' )     === '' 
        )
      {
          setDisable( true );
                                                  // valid data
          async function createClient( data )
          {
            changeClient( data ).then(
              async res => {
                if(res.status === 'OK'){
                  Swal.fire({
                    title: 'Cliente editado',
                    text: "El cliente se modificó correctamente",
                    icon: 'success',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                  });
                  await timeout(2000);
                  rstClient();
                  navigate('/clients');

                }
                else{
                  
                  if( res.close_session )
                  {
                    Swal.fire({
                      title: 'Se produjo un error',
                      text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                      icon: 'error',
                      timer: TIMEOT_SESSION_CLOSES,
                      showCancelButton: false,
                      showConfirmButton: false                
                    });
                    await timeout( TIMEOT_SESSION_CLOSES );
                    context.logoutUser();
                    navigate('/');
                  }
                  else{
                    Swal.fire({
                      title: 'Se produjo un error',
                      text: 'Error: ' + res.msg,
                      icon: 'error',
                      showCancelButton: false,
                      showConfirmButton: true                
                    });                    
                  } 
                }
                setDisable( false );                
              }
            );
          } 
          var client = {
            'name':_name.trimStart(),
            'surname':_surname.trimStart(),
            'address':_address,
            'phone':_phone,
            'email':_email,
            'cbu1':_cbu1,
            'cbu2':_cbu2,
            'city':_city,
            'state':_state,
            'boss':_boss,
            'local_bank':isChecked,
            'id':_id
          }

          if( client_data.dni !== _document)client.document = _document;

          console.log( client )
          createClient( client );
      }
  }

const form_name = _name;
const form_surname = _surname;
const form_dni = _document;
const form_address = _address;
const form_phone = _phone;
const form_email = _email;
const form_cbu1 = _cbu1;
const form_cbu2 = _cbu2;
const form_boss = _boss;


return(
    <Layout>
        <div className="card" style={{width: '65vw'}}>
          <div className="card-header bg-primary">Datos del cliente</div> 
          <div className="card-body col-lg">

            <div className='d-flex'>
              <div className="col-2 text-dark">Nombre:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input value={form_name} disabled={disable} className="form-control"  type="text" id="name" name="name" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_name}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Apellido:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  value={form_surname} disabled={disable} className="form-control"  type="text" id="surname" name="surname" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_surname}</div>
                </div>                                
              </div >   
            </div>


            <div className='d-flex'>
              <div className="col-2 text-dark">DNI:</div >   
              <div className="col-2">
                <div className={classes.inputTextContainer}>
                  <input  value={form_dni} disabled={disable} className="form-control"  type="text" id="document" name="document" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_document}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Domicilio:</div >   
              <div className="col-6">
                <div className={classes.inputTextContainer}>
                    <input value={form_address} disabled={disable} className="form-control"  type="text" id="address" name="address" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_address}</div>
                </div>                                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">Provincia:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <div className="border">
                      <div  className={classes.listContainer}>
                        {
                          _states.map(
                            state =>{
                              return <div className='text-dark' style={{display:'flex'}} key={state.id}>
                                <div style={{width:'20px'}}>
                                  <input disabled={disable} checked={ _state === state.nombre } type="radio" value={state.nombre} name="state" onChange={handleStateSelect}/>
                                </div>
                                {state.nombre}
                                </div>
                            })
                        }
                      </div>
                    
                  </div>
                  <div className="text-danger h6">{error_state}</div>  
                </div>                
              </div >   
              <div className="col-2 text-dark">Ciudad:</div >   
              <div className="col-4">
              <div className={classes.inputTextContainer}>
                  <div className="border">
                      <div  className={classes.listContainer}>
                        {
                          _cities.map(
                            city =>{
                              return <div className='text-dark' style={{display:'flex'}} key={city.id}>
                                <div style={{width:'20px'}}>
                                  <input disabled={disable} checked={ city.nombre === _city}  type="radio" value={city.nombre} name="cities" onChange={handleCitySelect}/>
                                </div>
                                {city.nombre}
                                </div>
                            })
                        }
                      </div>
                    
                  </div>
                  <div className="text-danger h6">{error_city}</div>  
                </div>                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">Teléfono:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input value={form_phone} disabled={disable}  className="form-control"  type="text" id="phone" name="phone" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_phone}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Email:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  value={form_email} disabled={disable} className="form-control"  type="email" id="email" name="email" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_email}</div>
                </div>                                
              </div >   
            </div>


            <div className='d-flex'>
              <div className="col-2 text-dark">CBU(1):</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input  value={form_cbu1} disabled={disable} className="form-control"  type="text" id="cbu1" name="cbu1" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_cbu1}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">CBU(2):</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  value={form_cbu2} disabled={disable} className="form-control"  type="text" id="cbu2" name="cbu2" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_cbu2}</div>
                </div>                                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">CBU banco provincial:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <div>              
                    <Form>
                      <Form.Check
                        type="switch"
                        id="toggle-switch"
                        label="Ventas"
                        checked={isChecked}
                        onChange={handleToggle}
                        disabled={disable}
                      />
                    </Form>
                  </div>
                  
                </div>                
              </div >   
              <div className="col-2 text-dark">Empleador:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input value={form_boss} disabled={disable}  className="form-control"  type="text" id="boss" name="boss" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_boss}</div>
                </div>                                
              </div >   
            </div>            



            <div className='d-flex'>
            <div className="col-4"></div>
              <div className="col-2 text-dark">Habilitar edición:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <div>
                    <Form>
                      <Form.Check
                        type="switch"
                        id="toggle-switch-edit"
                        label="Editar"
                        checked={isCheckedEdit}
                        onChange={handleToggleEdit}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>      






            <div className='d-flex'>
              <div className="col-sm"></div >   
              <div className="col-sm">
                <div className='d-flex'>
                  <div className="col-sm"><Button disabled={disable && !isCheckedEdit} className="btn btn-primary"   onClick={handlerBtn_newClient}>Editar cliente</Button></div>
                  <div className="col-sm"><Button  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                </div >   
              </div >   
              <div className="col-sm"></div >   
            </div >   
          </div>
        </div>
        <div className='spacer'><br></br></div>
    </Layout>
  )
}


export default EditClient