import React,{ useRef,useEffect, useState,useContext} from 'react';
import Layout from "../components/Layout";
import classes from "../components/Sales.module.scss";
import { getSales,deleteSale, getClientDebts,getAllClientSales } from "../Services/SalesServices";
import Button from "react-bootstrap/Button";
import icon_view_sales from '../assets/images/view-sales.png';
import icon_delete from '../assets/images/delete-icon.png';
import AuthContext from '../Context/AuthContext';
import {StringToBinary, StringFromBinary} from "../utils/Base64Utils"

import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';

import {useParams} from "react-router-dom";

function Sales(){

/** ************************************************************************************** 
 * function variables and constants
   ***************************************************************************************/

    const context = useContext(AuthContext);
    const TIMEOT_SESSION_CLOSES = 3000;
                                                            // gets client data
    const {client} = useParams();
    const [clientEncoded, setClientEncoded] = useState(client);
    var client_data = JSON.parse(StringFromBinary( client ));
    const navigate = useNavigate();
    
    var client_data = JSON.parse(StringFromBinary( client ));
    var client_surname = client_data.apellidonombre.substring(0,client_data.apellidonombre.indexOf(","));
    var client_name = client_data.apellidonombre.substring(client_data.apellidonombre.indexOf(",")+1,client_data.apellidonombre.length);
    console.log( 'name: ' +  client_name + ' - surname: ' + client_surname  );

    const [_name, setName] = useState(client_surname + ', '+ client_name);
    const [_document, setDocument] = useState(client_data.dni);    

    const form_name = _name;
    const form_document = _document;    

    const [dateFrom, setDateFrom] = useState('');
    const dateFromInputRef = useRef(null);

    const [disable, setDisable] = useState(false);

    const handleBtnNewSale = event => {
        navigate('/sales/new/'+clientEncoded);
    }

    const [clientSales, setClientSales] = useState([]);

                                                                            // FE13102023-01  
    const [clientDebts, setClientDebts] = useState([]);


    const handleChangeDateFrom = (e) => {
        setDateFrom(e.target.value);
      };

    const [dateTo, setDateTo] = useState('');
    const dateToInputRef = useRef(null);

    const handleChangeDateTo = (e) => {
        setDateTo(e.target.value);
      };      

    const handlerBtn_cancel = event =>{
        navigate('/clients');
      }
      const [findError, setFindError] = useState('');


                                                            // FE07112023-02
      const [current_sale_index , set_current_sale_index] = useState( 0 );


    /** ***********************************************************************************
     * update_client_debts()
    ***************************************************************************************/
                                                            // FE13102023-01
    function update_client_debts()
    {
        const ob = {
            id_cliente:client_data.id_cliente
        };

        getClientDebts( ob ).then(
            res =>{
                console.log( res )
                if( res.status === 'OK' ){


                    const _array = res.msg.client_debt;
                    
                    _array.forEach(
                        month => {month.total = Math.round((month.total + Number.EPSILON) * 100) / 100;}

                    );

                    setClientDebts( _array );
                }
                    
                
            }
        );
    }


    useEffect (( ) => {
        update_client_debts();
        fn_find_all_sales( 1, 0,0 );
      }, [ ]);


/** ***********************************************************************************
 * findAllSales: gets all client's sales (FE07112023-2)
***************************************************************************************/

    const findAllSales = event =>{
        console.log( clientSales );
        var forward = 0;
        var current_index = 0;
        var current_timestamp = 0;

        switch( event.target.value ){
            case 'forward':
                if( clientSales.length > 0 ){
                    current_index = clientSales[ clientSales.length -1 ].sales_id;
                    current_timestamp = clientSales[ clientSales.length -1 ].timestamp;
                    fn_find_all_sales( forward, current_index,current_timestamp );
                }
                else fn_find_all_sales( 1, 0, 0 );
                break;
            case 'backward':
                if( clientSales.length > 0 ){
                    
                    current_index = clientSales[ 0 ].sales_id;
                    current_timestamp = clientSales[ 0 ].timestamp;
                    forward = 1;
                    fn_find_all_sales( forward, current_index,current_timestamp );
                }
                else fn_find_all_sales( 1, 0, 0 );
                
                break;                
        }

        
    }


/** ************************************************************************************** 
 * fn_find_all_sales(): gets all client's sales (FE07112023-2)
   ***************************************************************************************/

    function fn_find_all_sales( forward, current_index,current_timestamp ){
        console.log( "fn_find_all_sales" )
        set_current_sale_index( current_index );
        const _from = current_index;
        const _forward  = forward;

        setFindError( "" );
        setDisable( true );
        const _ob = {
            id_cliente:client_data.id_cliente,
            from:_from,
            forward:_forward,
            timestamp:current_timestamp
        }

        console.log( _ob )
        getAllClientSales( _ob ).then(
            async res => {
                if(res.status !== 'OK'){
                    Swal.fire({
                        title: 'Se produjo un error',
                        text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                        icon: 'error',
                        timer: TIMEOT_SESSION_CLOSES,
                        showCancelButton: false,
                        showConfirmButton: false                
                      });
                      
                      await timeout( TIMEOT_SESSION_CLOSES );
                      context.logoutUser();
                      navigate('/');
                      
                }
                else{
                    console.log( res.msg );
                    res.msg.forEach(element => {
                        element.friendly_date = getDate( element.timestamp );
                        element.payment.forEach(
                            pay =>{
                                pay.friendly_date = getDate( pay.timestamp );
                            }
                        );
                        console.log( element )
                    });
                    setClientSales( res.msg );
                }
                setDisable( false );                
              }
        );   
    }






    // for see sales you need use this later
    function getUTCfromLocal( dateFrom ){
        return (new Date(dateFrom)).getTime();
        
      }

    function fn_find_sales(){
        var _dateFrom = getUTCfromLocal(new Date( dateFrom ));
        var _dateTo   = getUTCfromLocal(new Date( dateTo ));
        if( _dateTo < _dateFrom )setFindError( "La fecha desde debe ser anterior a hasta" );
        else{


                                                                    // FE26012023: if some date is null, then set dates to current date
            if( !_dateTo || !_dateFrom ){
                _dateFrom = getUTCfromLocal(new Date());
                _dateTo   = getUTCfromLocal(new Date());
            }


            setFindError( "" );
            setDisable( true );
            const _ob = {
                'id_cliente':client_data.id_cliente,
                'from':_dateFrom,
                'to':_dateTo
            }
            console.log( _ob )
            getSales( _ob ).then(
                async res => {
                    if(res.status !== 'OK'){
                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                          await timeout( TIMEOT_SESSION_CLOSES );
                          context.logoutUser();
                          navigate('/');
                    }
                    else{
                        console.log( res.msg );
                        res.msg.forEach(element => {
                            element.friendly_date = getDate( element.timestamp );
                            element.payment.forEach(
                                pay =>{
                                    pay.friendly_date = getDate( pay.timestamp );
                                }
                            );
                            console.log( element )
                        });
                        setClientSales( res.msg );
                    }
                    setDisable( false );                
                  }
            );
            setDisable( true );
        }
    }


    const findSales = event =>{
        //console.log("from: " + dateFrom);
        //console.log("to: "   + dateTo);
        if( dateFrom.length !==0 && dateTo.length !==0 ){
            fn_find_sales();
        }else setFindError( "Las fechas no pueden estar vacías" );
    }

    function getDate( data ){
        var now = new Date( +data );        
        var year = now.getUTCFullYear();
        var month = now.getUTCMonth() + 1;
        if( month < 10 )month = '0'+month;
        var day = now.getUTCDate();
        if( day < 10 )day = '0'+day;
        return day + '-'+month +'-'+year;
    }

    const handleViewSale = ev =>{
        console.log('sale Id:' + ev.target.id);
        var _sale = null;
        clientSales.forEach(
            sale =>{
                if(sale.sales_id === ev.target.id )_sale = sale;
            }
        );
        const _data = {
            'sale':_sale,
            'client':client_data
        }
        console.log( _data );
        const sale_str = JSON.stringify( _data );
        navigate( '/sales/edit/'+ StringToBinary( sale_str ));
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }    

    const handleDSeleteSale = ev => {
        console.log( 'Eliminar: ' + ev.target.id )

        Swal.fire({
            title: 'Eliminar venta Id: ' + ev.target.id,
            text: '¿Confirma la eliminación de la venta?. Se perderá la información relacionada a la misma. ',
            icon: 'warning',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
          }).then(
            function(isConfirm) 
            {
                if(isConfirm.isConfirmed){
                    setDisable( true );                
                    console.log('delete in process.. ID: ' + ev.target.id);
                    deleteSale( ev.target.id ).then(
                        
                            async res => {
                                if(res.status !== 'OK'){

                                    Swal.fire({
                                        title: 'Se produjo un error',
                                        text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                                        icon: 'error',
                                        timer: TIMEOT_SESSION_CLOSES,
                                        showCancelButton: false,
                                        showConfirmButton: false                
                                      });
                                      await timeout( TIMEOT_SESSION_CLOSES );
                                      context.logoutUser();
                                      navigate('/');
                                      

                                }
                                else{
                                        Swal.fire({
                                          title: 'Venta eliminada',
                                          text: 'La venta y sus registros asociados se eliminaron correctamente',
                                          icon: 'success',
                                          timer: 2000,
                                          showCancelButton: false,
                                          showConfirmButton: false
                                        });
                                        await timeout(2000);
                                        fn_find_sales();
                              }
                            setDisable( false );                  
                        }
                    );
                }            
            });
    }


    return(
        <Layout>
            <div className="card" style={{width: '65vw'}}>
                <div className="card-header bg-primary">Gestión de ventas</div> 
                    <div className="card-body col-lg">

                        <div className='d-flex'>
                            <div className="col-2 text-dark">Cliente:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input value={form_name} disabled={true} className="form-control"  type="text" id="name" name="name" />
                                </div>                
                            </div >  
                        
                            <div className="col-2 text-dark">Documento:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input  value={form_document} disabled={true} className="form-control"  type="text" id="document" name="document"/>
                                </div>                                
                            </div >   
                        </div>

                        <div className="col-sm"></div > 


                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Resumen de deuda mensual</div >   
                        </div > 


                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>
                                <div  style={{width: '60vw',overflowX:'scroll',height:'10vh'}}>
                                    <div className="d-flex">
                                    {
                                        
                                        clientDebts.map( deb => {
                                            return <div style={{width: '20vw'}}  key={deb.month_year} >
                                                <div  className=" text-dark">{deb.month_year}</div>
                                                <div className=" text-secondary">{deb.total}</div>
                                            </div>
                                        })
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                        </div >   

                        <div className={classes.spacer}></div>

                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Nueva venta</div >   
                        </div > 

                        <div className="col-lg">
                            <div className={classes.new_sale_container}>
                                <div className='d-flex '>
                                    <div className="col-6 text-dark">
                                        Genere una nueva venta para el cliente   
                                    </div >                               
                                    <div className="col-6 text-dark">
                                        <Button  className="btn btn-success" onClick={handleBtnNewSale} disabled={disable}>Generar</Button>
                                    </div >                                                               
                                </div>
                            </div>    
                        </div >   

                        
                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Ventas registradas</div >   
                        </div >                         


                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>

                                <div className="col-4 text-dark">
                                    Búsqueda en rango de fechas:
                                </div >                               
                                <div className={classes.sales_finder_container}> 
                                    <div className='d-flex '>
                                        <div className="col-1 text-dark">
                                            Desde:
                                        </div >                               

                                        <div className="col-4">
                                            <div>
                                                <input
                                                    disabled={disable}
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateFrom}
                                                    ref={dateFromInputRef}
                                                />
                                            </div>
                                        </div > 

                                        <div className="col-1 text-dark">
                                            Hasta:
                                        </div >   
                                        <div className="col-4">
                                            <div>
                                                <input
                                                    disabled={disable}
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateTo}
                                                    ref={dateToInputRef}
                                                />
                                            </div>
                                        </div >

                                        <div className="col-1 text-dark">
                                            <Button disabled={disable}  className="btn btn-success" onClick={findSales}>Buscar</Button>
                                        </div >
                                    </div>
                                    <div className="text-danger h6">{findError}</div>
                                </div>         
                            </div>    

                        </div >   


                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>

                                <div className={classes.sales_finder_container}> 
                                    <div className='d-flex' >
                                        <div className="col-4"></div>
                                        <div className="col-1 text-dark">
                                            <Button disabled={disable}  value='backward' className="btn btn-success" onClick={findAllSales}>Anteriores</Button>
                                        </div >
                                        <div className="col-2"></div>
                                        <div className="col-1 text-dark">
                                            <Button disabled={disable}  value='forward' className="btn btn-success" onClick={findAllSales}>Siguientes</Button>
                                        </div >                                        
                                        <div className="col-4"></div>
                                    </div>
                                </div>         
                            </div>    

                        </div >   


                        <div className="col-lg">
                            <div className={classes.ClientResultLine}>
                                <div className={classes.ClientsHeadItems_1}>
                                    Id de venta
                                </div>
                                <div className={classes.ClientsHeadItems_2}>
                                        Importe de venta
                                </div>                                                
                                <div className={classes.ClientsHeadItems_3}>
                                        Cuotas iniciales
                                </div>
                                <div className={classes.ClientsHeadItems_3}>
                                        Fecha
                                </div>    
                                <div className="col-1"></div>   
                                <div className={classes.ClientsOptions}>
                                        Opción
                                </div>                                                                                                                
                            </div>  
                        </div >   

                        <div className="col-lg">
                            <div className={classes.listContainer}>                              
                                {
                                    clientSales.map(
                                        sale => {
                                            return <div key={sale.sales_id} className={classes.ClientResultLine}>
                                                <div className={classes.ClientsHeadItems_1}>
                                                        {sale.sales_id}
                                                </div>
                                                <div className={classes.ClientsHeadItems_2}>
                                                        {sale.cash}
                                                </div>                                                
                                                <div className={classes.ClientsHeadItems_3}>
                                                        {sale.pays_number}
                                                </div>
                                                <div className={classes.ClientsHeadItems_3}>
                                                        {sale.friendly_date}
                                                </div>
                                                <div className="col-1"></div>   
                                                <div className={classes.ClientsOptions}>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <div title="Información de ventas">
                                                            <img onClick={handleViewSale} className={classes.logo}  id={sale.sales_id} src={icon_view_sales}/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div title="Eliminar venta">
                                                            <img  onClick={handleDSeleteSale} id={sale.sales_id} className={classes.logo} src={icon_delete}/>
                                                            </div>
                                                        </div>                                                    
                                                    </div>    
                                                </div>
                                                                                           
                                            </div>
                                        }
                                    )
                                }
                            </div>
                        </div>                            

                        <div className="col-sm">
                            <div className='d-flex'>
                                <div className="col-sm"><Button  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                            </div >   
                        </div >   
                        <div className="col-sm"></div >   
                        </div >                           
                    </div>                    
                    <div className={classes.spacer}></div>
            
        </Layout>    
    );
}

export default Sales