import React,{ useRef,useEffect, useState,useContext} from 'react';
import Layout from "../components/Layout";
import classes from "../components/Sales.module.scss";
import { getAllSales,UpdatePayment, UpdateAllPayments } from "../Services/SalesServices";
import Button from "react-bootstrap/Button";
import { validate_email,validate_file_name } from '../utils/validator';
import { sendMail,presentation } from '../Services/Operations';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext';

import {ExportExel} from '../utils/excel_exporter';
import { OrderSalesByClientNameAndDate } from '../utils/orderUtils'

function SalesViewer(){
    const context = useContext(AuthContext);
    const TIMEOT_SESSION_CLOSES = 3000;
                                                            // gets client data

    const navigate = useNavigate();
    const [dateFrom, setDateFrom] = useState('');
    const dateFromInputRef = useRef(null);
    const [disable, setDisable] = useState(false);
    const [clientSales, setClientSales] = useState([]);


    const [sale_cuero_total, set_sale_cuero_total]  = useState(0);
    const [sale_ventas_total, set_sale_venta_total] = useState(0);
    const [sale_otros_total, set_sale_otros_total]  = useState(0);

    const handleChangeDateFrom = (e) => {
        setDateFrom(e.target.value);
      };

    const [dateTo, setDateTo] = useState('');
    const dateToInputRef = useRef(null);

    const handleChangeDateTo = (e) => {
        setDateTo(e.target.value);
      };      

    const handlerBtn_cancel = event =>{
        navigate('/clients');
      }
    
    const [findError, setFindError] = useState('');

    
                                                            // default file names
    var default_file_names = "";
    
    function generate_date(){
        const current_date = new Date();
        default_file_names = '_'
                    + current_date.getDate().toString();
        const _month = (current_date.getMonth()+1);
        var _month_string;
        if( _month < 10 )
        {
            _month_string = '0'+_month;
        }
        else _month_string = _month.toString();
        default_file_names = default_file_names + _month_string + current_date.getFullYear().toString();
                    
    }
    generate_date();
    

    
    const [ fileNameVentas, setFileNameVentas]            = useState('ORI_2'+default_file_names);
    const [ fileNameVentasCuero, setFileNameVentasCuero]  = useState('ORI_3'+default_file_names);

    const [ errorFileNameVentas, setErrorFileNameVentas]            = useState('');
    const [ errorFileNameVentasCuero, setErrorFileNameVentasCuero]  = useState('');


                                                                            // 27-09-2023: FE27092023-1
    const [ _paid_for_all, _set_paid_for_all] = useState(false);
    const [ _presented_for_all, _set_presented_for_all] = useState(false);




    // for see sales you need use this later
    function getUTCfromLocal( dateFrom ){
        return (new Date(dateFrom)).getTime();
        
      }


    /*****************************************************************************
     * fn_find_sales()
    *****************************************************************************/                

    function fn_find_sales(){
        const _dateFrom = getUTCfromLocal(new Date( dateFrom ));
        const _dateTo   = getUTCfromLocal(new Date( dateTo ));
        if( _dateTo < _dateFrom )setFindError( "La fecha desde debe ser anterior a hasta" );
        else{
            setFindError( "" );
            setDisable( true );
            const _ob = {
                'from':_dateFrom,
                'to':_dateTo
            }
            //console.log( _ob );
            
            getAllSales( _ob ).then(
                async res => {
                    if(res.status !== 'OK'){

                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Error: ' + res.msg + '. Se cerrará la sesión en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                          await timeout( TIMEOT_SESSION_CLOSES );
                          context.logoutUser();
                          navigate('/');
                    }
                    else{
                        console.log( res.msg );
                        var id_ref = 0;

                        // total sales
                        var ventas = 0;
                        var ventas_cuero = 0;
                        var ventas_otros = 0;

                        res.msg.forEach(element => {
                            element.id = id_ref;
                            id_ref++;

                            switch(element.type ){
                                case 'VENTAS': ventas = ventas + Number.parseFloat(element.value);break;
                                case 'VENTAS CUERO': ventas_cuero = ventas_cuero + Number.parseFloat(element.value);break;
                                case 'VENTAS OTROS': ventas_otros = ventas_otros + Number.parseFloat(element.value);break;
                            }

                            element.friendly_date = getDate( element.timestamp );

                            if( element.paid === '0' ){
                                element.checked_paid = false;
                              }
                              else {
                                element.checked_paid = true;
                              }

                              if( element.presented === '0' ){
                                element.checked_presented = false;
                              }
                              else {
                                element.checked_presented = true;
                              }

                            //console.log( element )
                        });

                        ventas_cuero = Math.round((ventas_cuero + Number.EPSILON) * 100) / 100
                        ventas_otros = Math.round((ventas_otros + Number.EPSILON) * 100) / 100
                        ventas = Math.round((ventas + Number.EPSILON) * 100) / 100


                        // here needs be ordered by client name and after sale date
                        //console.log( res.msg ));

                        setClientSales( OrderSalesByClientNameAndDate(res.msg) );
                        set_sale_cuero_total( ventas_cuero);
                        set_sale_otros_total(ventas_otros);
                        set_sale_venta_total( ventas );
                    }
                    setDisable( false );                
                  }
            );
            setDisable( true );
        }
    }


    /*****************************************************************************
     * findSales: sales finder handler
    *****************************************************************************/          

    const findSales = event =>{
        //console.log("from: " + dateFrom);
        //console.log("to: "   + dateTo);
        if( dateFrom.length !==0 && dateTo.length !==0 ){
            fn_find_sales();
        }else setFindError( "Las fechas no pueden estar vacías" );
    }


    /*****************************************************************************
     * getDate: like pipe, converts date formats to friendly one
    *****************************************************************************/          

    function getDate( data ){
        var now = new Date( +data );        
        var year = now.getUTCFullYear();
        var month = now.getUTCMonth() + 1;
        if( month < 10 )month = '0'+month;
        var day = now.getUTCDate();
        if( day < 10 )day = '0'+day;
        return day + '-'+month +'-'+year;
    }



    /*****************************************************************************
     * timeout: implements a delay
    *****************************************************************************/          

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }    


    /*****************************************************************************
     * handlePaydChange
    *****************************************************************************/      

      const handlePaydChange = ev =>{
        console.log( ev );
        setDisable( true );
        const _arr = []
        clientSales.forEach( pay =>{
         
          if( pay.id === Number(ev.target.id) && ev.type === 'click')
          {

                                                    // change if success
            var _paid_status = Number(pay.paid);
            if( _paid_status === 1 )_paid_status = 0;
            else _paid_status = 1;
            
            const _ob = {
                'payment_id':Number(pay.payment_id),
                'paid':_paid_status,
                'presented':Number(pay.presented),
            }
            console.log( _ob )
            UpdatePayment( _ob ).then(
                async res => {
                    if(res.status !== 'OK'){


                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                          await timeout( TIMEOT_SESSION_CLOSES );
                          context.logoutUser();
                          navigate('/');


                    }
                    else{
                        if( pay.paid === '0' ){
                            pay.paid = '1';
                            pay.checked_paid = true;
                            }
                            else {
                            pay.paid = '0';
                            pay.checked_paid = false;
                        }
                    }
                    setDisable( false );
                }                
            );
          }
          _arr.push( pay )
        });
        setClientSales(_arr)
      }


    /*****************************************************************************
     * handlePresentedChange
    *****************************************************************************/

      const handlePresentedChange = ev =>{
        console.log( ev );
        setDisable( true );
        const _arr = []
        clientSales.forEach( pay =>{
         
          if( pay.id === Number(ev.target.id) && ev.type === 'click')
          {

                                                    // change if success
            var _paid_status = Number(pay.presented);
            if( _paid_status === 1 )_paid_status = 0;
            else _paid_status = 1;

            const _ob = {
                'payment_id':Number(pay.payment_id),
                'presented':_paid_status,
                'paid':Number(pay.paid),
            }
            console.log( _ob )
            UpdatePayment( _ob ).then(
                async res => {
                    if(res.status !== 'OK'){


                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                          await timeout( TIMEOT_SESSION_CLOSES );
                          context.logoutUser();
                          navigate('/');

                    }
                    else{
                        if( pay.presented === '0' ){
                            pay.presented = '1';
                            pay.checked_paid = true;
                            pay.checked_presented = true;
                            }
                            else {
                            pay.presented = '0';
                            pay.checked_presented = false;
                        }
                    }
                    setDisable( false );
                }                
            );
          }
          _arr.push( pay )
        });
        setClientSales(_arr)
      }      



    /*****************************************************************************
     * fnSendEmails()
    *****************************************************************************/

    function fnSendEmails(){
        //setDisable( true );
        /*
        const distributionList = [];
        clientSales.forEach(
            reg =>{
                var client = null;
                client = distributionList.find( element => element.id_cliente === reg.id_cliente );
                if( !client ){
                    const _ob = {
                        'id_cliente':reg.id_cliente,
                        'apellidonombre':reg.apellidonombre,
                        'email':reg.email,
                        'paid':[]
                    }
                    distributionList.push(_ob);
                }
            }
        );
          
        distributionList.forEach(
            reg => {
                clientSales.forEach(
                    reg_sales =>{
                        if( reg_sales.email )
                        {
                            if( 
                                reg.id_cliente === reg_sales.id_cliente && 
                                Number(reg_sales.paid) === 1 &&
                                validate_email( reg_sales.email )
                                )reg.paid.push( reg_sales )
                        }

                    });
            }
        );
        console.log(distributionList);
        */
        

        /*
        const mail_lis = [];
        distributionList.forEach(
            reg =>{
                if(reg.paid.length > 0)mail_lis.push(fn_build_email_msg( reg ));
            }
        );
        console.log( mail_lis );
        */

                                                                            // 17-09-2023
        const _dateFrom = getUTCfromLocal(new Date( dateFrom ));
        const _dateTo   = getUTCfromLocal(new Date( dateTo ));

        const ob = {
            'option':'PENDENT_PAYMENT',
            'from': _dateFrom,
            'to': _dateTo,
            //'pass':'s3LyuTra$.G'
        };

        sendMail(ob).then(
            res => 
            {
                if(res.status !== 'OK'){

                    Swal.fire({
                        title: 'Se produjo un error',
                        text: 'Es probable que haya ocurrido un error al enviar los correos, controle la configuración de su cuenta de Gmail',
                        icon: 'error',
                        timer: TIMEOT_SESSION_CLOSES,
                        showCancelButton: false,
                        showConfirmButton: false                
                      });
                      
                  }
                  else
                  {
                    Swal.fire({
                        title: 'Emails enviados',
                        text: res.msg,
                        icon: 'success',
                        confirmButtonText: 'Cerrar'
                      });                    
                  }

                setDisable( false );
            }
        );
        
    }

    /*****************************************************************************
     * fn_build_email_msg: unused fro last update. Message are build in in server
    *****************************************************************************/

    function fn_build_email_msg( data ){
        console.log( data );
        var subject = 'Reporte de pago';
        var message = '<div><p>Estimada/o ' + data.apellidonombre + ': este es un mensaje automático para informarle ' + 
        'que registramos los siguientes pagos: </p>';
        data.paid.forEach(
            reg => {
                message = message + '- Cuota con fecha de pago ' + reg.friendly_date + ' por un importe de $ ' +
                            reg.value + ', abonados por ';
                if( reg.type === 'VENTAS' || reg.type === 'VENTAS CUERO')message = message + 'débito bancario.';
                else message = message + 'efectivo, transferencia u otro método de pago.';
                message = message + '<br>';
            }
        );
        message = message + '<p>Saludos cordiales.</p></div>';
        const ret = {
            'to':data.email,
            'subject':subject,
            'message':message
        }
        return ret;
    }


    /*****************************************************************************
     * handleChangeFileName
    *****************************************************************************/
    
    const  handleChangeFileName = (ev) =>{

        console.log( ev.target.id )

        var option = 'ventas';
        if( ev.target.id === 'file_ventas_cuero'){
            setFileNameVentasCuero( ev.target.value );
            option = 'ventas_cuero';
        }
        if( ev.target.id === 'file_ventas')setFileNameVentas( ev.target.value );

        if( option === 'ventas' ){
            if(ev.target.value.length !== 0){
                if(!validate_file_name(ev.target.value))setErrorFileNameVentas('Nombre de archivo no valido');
                else setErrorFileNameVentas('');
            }
            else setErrorFileNameVentas('Cambo obligatorio');
        }
        if( option === 'ventas_cuero'){
            if(ev.target.value.length !== 0){
                if(!validate_file_name(ev.target.value))setErrorFileNameVentasCuero('Nombre de archivo no valido');
                else setErrorFileNameVentasCuero('');
            }
            else setErrorFileNameVentasCuero('Cambo obligatorio');    
        }
    }


    /*****************************************************************************
     * function handleBtonNewFile()
    *****************************************************************************/

    function handleBtonNewFile()
    {
        //setDisable( true )
        if( errorFileNameVentasCuero.length === 0 && errorFileNameVentas.length === 0 )
        {

            const _dateFrom = getUTCfromLocal(new Date( dateFrom ));
            const _dateTo   = getUTCfromLocal(new Date( dateTo ));            
                                                                // here generates files
            const _ob = {
                'from':_dateFrom,
                'to':  _dateTo,
                'file_name_ventas': fileNameVentas,
                'file_name_ventas_cuero': fileNameVentasCuero,
            }
         
            presentation( _ob).then( 
                res =>{
                    if(res.status !== 'OK'){

                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                          context.logoutUser();
                          navigate('/');                        
                        
                      }
                      else
                      {
                        Swal.fire({
                            title: 'Archivos generados',
                            text: res.msg,
                            icon: 'success',
                            confirmButtonText: 'Cerrar'
                          });                    
                          fn_find_sales();
                      }
                      setDisable( false )
                });
         
        }
    }


    /*****************************************************************************
     * handleBtnExportToExcel(): exports current content to excel file
    *****************************************************************************/

    function handleBtnExportToExcel()
    {
        var _excel_file_name = 'reporte_ventas_henry_app_' + getDate(Date.now());
        if( clientSales.length > 0 )
        {
            console.log('File name:' +  _excel_file_name );
            ExportExel( clientSales,  _excel_file_name )
        }
    }



                                                                                    // 27-09-2023: FE27092023-1

    /*****************************************************************************
     * handle_set_group_paid:
    *****************************************************************************/

    const handle_set_group_paid = (ev) =>{
        _set_paid_for_all( ev.target.checked)
    }



    /*****************************************************************************
     * handle_set_group_presented:
    *****************************************************************************/

    const handle_set_group_presented = (ev) =>{
        _set_presented_for_all( ev.target.checked)
    }

    /*****************************************************************************
     * handle_aply_changes_for_all register:
    *****************************************************************************/
    function handle_aply_changes_for_all_register(){
        if( dateFrom.length !==0 && dateTo.length !==0 && clientSales.length > 0 )
        {
            const _dateFrom = getUTCfromLocal(new Date( dateFrom ));
            const _dateTo   = getUTCfromLocal(new Date( dateTo ));

            setDisable( true );
            const _ob = {
                from:_dateFrom,
                to:_dateTo,
                presented:_presented_for_all,
                paid:_paid_for_all
            }

            UpdateAllPayments( _ob ).then(
                async res => {
                    if(res.status !== 'OK'){

                        Swal.fire({
                            title: 'Se produjo un error',
                            text: 'Error: ' + res.msg + '.',
                            icon: 'error',
                            timer: TIMEOT_SESSION_CLOSES,
                            showCancelButton: false,
                            showConfirmButton: false                
                          });
                    }
                    else{
                        fn_find_sales();
                    }
                    setDisable( false );                
                  }
            );
        }
    }
/*

    const [ _paid_for_all, _set_paid_for_all] = useState(false);
    const [ _presented_for_all, _set_presented_for_all] = useState(false);

*/ 


    return(
        <Layout>
            <div className="card" style={{width: '75vw'}}>
                <div className="card-header bg-primary">Vista de ventas</div> 
                    <div className="card-body col-lg">
                        
                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Ventas registradas</div >   
                        </div >                         


                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>

                                <div className="col-4 text-dark">
                                    Búsqueda en rango de fechas:
                                </div >                               
                                <div className={classes.sales_finder_container}> 
                                    <div className='d-flex '>
                                        <div className="col-1 text-dark">
                                            Desde:
                                        </div >                               

                                        <div className="col-4">
                                            <div>
                                                <input
                                                    disabled={disable}
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateFrom}
                                                    
                                                    ref={dateFromInputRef}
                                                />
                                            </div>
                                        </div > 

                                        <div className="col-1 text-dark">
                                            Hasta:
                                        </div >   
                                        <div className="col-4">
                                            <div>
                                                <input
                                                    disabled={disable}
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateTo}
                                                   
                                                    ref={dateToInputRef}
                                                />
                                            </div>
                                        </div >

                                        <div className="col-1 text-dark">
                                            <Button disabled={disable}  className="btn btn-success" onClick={findSales}>Buscar</Button>
                                        </div >
                                    </div>
                                    <div className="text-danger h6">{findError}</div>
                                </div>         
                            </div>    

                        </div >   



                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>

                                <div className="col-4 text-dark">
                                    Aplicar cambios al grupo de datos:
                                </div >                               
                                <div className={classes.sales_finder_container}> 
                                    <div className='d-flex '>
                                    <div className="col-1"></div>
                                        <div className="col-2 text-dark">
                                            Todos pagados:
                                        </div >                               

                                        <div className="col-1">
                                            <div>
                                                <input type="checkbox" 
                                                            id="set_group_paid"
                                                            onClick={handle_set_group_paid}
                                                            disabled={disable} 
                                                            />
                                            </div>
                                        </div > 

                                        <div className="col-2 text-dark">
                                            Todos presentados:
                                        </div >   
                                        <div className="col-1">
                                            <div>
                                                <input type="checkbox" 
                                                            id="set_group_presented"
                                                            onClick={handle_set_group_presented}
                                                            disabled={disable} 
                                                            />
                                            </div>
                                        </div >
                                        <div className="col-1"></div>
                                        <div className="col-2 text-dark">
                                            <Button disabled={disable}  className="btn btn-success" onClick={handle_aply_changes_for_all_register}>Aplicar cambios</Button>
                                        </div >
                                    </div>
                                    <div className="text-danger h6">{findError}</div>
                                </div>         
                            </div>    

                        </div >   

                        <div className={classes.spacer}></div>





                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Importes</div >   
                        </div > 


                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Ventas cuero:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>{sale_cuero_total}</div >   
                        </div > 
                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Ventas:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>{sale_ventas_total}</div >   
                        </div > 

                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Ventas otros:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>{sale_otros_total}</div >   
                        </div > 
                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Ventas totales:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>{sale_cuero_total+sale_otros_total+sale_ventas_total}</div >   
                        </div >                         


                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Opciones</div >   
                        </div > 

                        <div className={classes.spacer}>
                            
                        </div > 

                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_6}>Exportar datos a Excel:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>
                                <Button onClick={handleBtnExportToExcel} disabled={disable} className="btn btn-primary" style={{width:"150px"}}>Exportar</Button>
                            </div >   
                        </div > 


                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_6}>Informar pagos por email:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>
                                <Button onClick={fnSendEmails} disabled={disable} className="btn btn-primary" style={{width:"150px"}}>Enviar emails</Button>
                            </div >   
                        </div > 


                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_6}>Generar archivos de débito:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_5}>
                                <Button onClick={handleBtonNewFile} disabled={disable} className="btn btn-primary" style={{width:"150px"}}>Generar ahora</Button>
                            </div >                               
                        </div>
                        
                        <div className='d-flex'>
                            <div className="col-5" style={{color:'grey'}}>Nombre del archivo de ventas abiertas:</div >   
                            <div className="col-1"></div>   
                            <div className="col-3">
                                <div>
                                    <input
                                        disabled={disable}
                                        className="text-dark"
                                        value={fileNameVentas}
                                        type="text"
                                        id='file_ventas'
                                        onChange={handleChangeFileName}
                                        onBlur={handleChangeFileName}
                                        ref={dateFromInputRef}
                                        />
                                    


                                </div>
                                <div className="text-danger h6">{errorFileNameVentas}</div>
                            </div > 
                        </div > 
                        <div className={classes.spacer}></div >                         
                        <div className='d-flex'>
                        <div className="col-5" style={{color:'grey'}}>Nombre del archivo de ventas cerradas:</div >                               
                            <div className="col-1"></div>   
                            <div className="col-3">
                                <div>
                                    <input
                                        disabled={disable}
                                        className="text-dark"
                                        value={fileNameVentasCuero}
                                        type="text"
                                        id='file_ventas_cuero'
                                        onChange={handleChangeFileName}
                                        onBlur={handleChangeFileName}
                                        ref={dateFromInputRef}
                                        />
                                    
                                </div>
                                <div className="text-danger h6">{errorFileNameVentasCuero}</div>    
                            </div >                             

                        </div > 

                        <div className={classes.spacer}></div > 

                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Detalle</div >   
                        </div > 


                        <div className="col-lg">
                            <div className={classes.ClientResultLine}>
                                <div className={classes.ClientsHeadItems_3}>
                                    Fecha
                                </div>
                                <div className={classes.ClientsHeadItems_8}>
                                        Cliente
                                </div>                                                
                                <div className={classes.ClientsHeadItems_3}>
                                        Tipo de venta
                                </div>
                                <div className={classes.ClientsHeadItems_2}>
                                        Importe
                                </div>    
                                <div className="col-1"></div>   
                                <div className={classes.ClientsHeadItems_1}>
                                        Pagado
                                </div>                       
                                <div className={classes.ClientsHeadItems_1}>
                                        Presentado
                                </div>                                                       

                            </div>  
                        </div >   

                        <div className="col-lg">
                            <div className={classes.listContainer}>                              
                                {

                                    clientSales.map(
                                        sale => {
                                            return <div key={sale.id} className={classes.ClientResultLine}
                                                        style={{
                                                            borderStyle:"solid none none none",
                                                            borderColor:"grey"
                                                        }}
                                                    >
                                                <div className={classes.ClientsHeadItems_3}>
                                                        {sale.friendly_date}
                                                </div>

                                                <div className={classes.ClientsHeadItems_8}>
                                                      <div 
                                                        style={{
                                                            width:"90%",
                                                            textAlign:"justify",
                                                            color:"black"
                                                            }}>  
                                                        {sale.apellidonombre} 
                                                      </div>
                                                </div>

                                                <div className={classes.ClientsHeadItems_3}>
                                                        {sale.type}
                                                </div>                    

                                                <div className={classes.ClientsHeadItems_2}>
                                                        {sale.value}
                                                </div>                                                                                                

                                                <div className="col-1"></div>   

                                                <div className={classes.ClientsHeadItems_1}>
                                                
                                                    <input type="checkbox" 
                                                        checked={ sale.checked_paid }
                                                        id={sale.id}
                                                        onChange={handlePaydChange} 
                                                        onClick={handlePaydChange}
                                                        disabled={disable} 
                                                        />
                                                
                                                </div>                                                                    

                                                <div className={classes.ClientsHeadItems_1}>
                                                    <input type="checkbox" 
                                                        checked={ sale.checked_presented }
                                                        id={sale.id}
                                                        onChange={handlePresentedChange} 
                                                        onClick={handlePresentedChange}
                                                        disabled={disable} 
                                                        />
                                                
                                                </div>            

                                                                                        
                                            </div>
                                        }
                                    )                                    


                                }
                            </div>
                        </div>                            

                        <div className="col-sm">
                            <div className='d-flex'>
                                <div className="col-sm"><Button  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                            </div >   
                        </div >   
                        <div className="col-sm"></div >   
                        </div >                           
                    </div>                    
                    <div className={classes.spacer}></div>
            
        </Layout>    
    );
}

export default SalesViewer