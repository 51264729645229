import React,{ useRef, useEffect, useContext } from "react";
import { loginUser,getUserData } from '../Services/UserServices';
import classes from "./LoginForm.module.scss";
import usernameIcon from "../assets/akar-icons_person.svg";
import passwordIcon from "../assets/carbon_password.svg";
//import ValidUserContext from "../authCheck";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext';



async function fnGetUserData(){
  const data = await getUserData();
  return data;
}


function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

function LoginForm() {
  //const validUserContext = useContext(ValidUserContext);
  const [disable, setDisable] = React.useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const context = useContext(AuthContext);



  /*
  useEffect(() => {
    if (isInitial) {
      validUserContext.localAuthCheck();
      isInitial = false;
    }
  }, [validUserContext]);
  */

  const submitHandler = (event) => {
    event.preventDefault();
    setDisable(true);
    try{
      const user = {
        user:emailInputRef.current.value,
        pass:passwordInputRef.current.value
      };

      loginUser( user ).then(
        async ret => {
          if( ret.status === 'error' ){
            Swal.fire({
              title: 'Error de login',
              text: ret.msg,
              icon: 'error',
              confirmButtonText: 'Cerrar'
            });
          }
          else{

            getUserData().then(
              async res_user => {
                if( res_user.status === 'error' ){
                  Swal.fire({
                    title: 'Se produjo un error',
                    text: ret.msg,
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                  });
                }
                else{
                  Swal.fire({
                    title: 'Login exitoso',
                    text: "Redirigiendo a usuarios",
                    icon: 'success',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                  });
                  context.loginUser( res_user.msg );
                  await timeout(2000);
                  console.log("redirigiendo...");
                  navigate('/clients');
                }
              } 
            );


          }
          setDisable(false);
        }
      );



    }catch(e){
      Swal.fire({
        title: 'Se produjo un error!',
        text: 'Intente mas tarde nuevamente.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
      console.log(e);
      setDisable(false);
    }
    /*
    validUserContext.apiAuthCheck(
      emailInputRef.current.value,
      passwordInputRef.current.value
    );
    */
  };

  return (
    <form onSubmit={submitHandler} className={classes.form}>
      <div>
        <img
          className={classes.icon}
          src={usernameIcon}
          alt="Username icon"
          htmlFor="user-name"
        ></img>
        <input
          disabled={disable}
          className={classes.input}
          type="email"
          id="user-name"
          name="user-name"
          autoComplete="on"
          placeholder="E-mail"
          ref={emailInputRef}
          required={!context.isLoggedIn}
        ></input>
      </div>

      <div>
        <img
          className={classes.icon}
          src={passwordIcon}
          alt="Password icon"
          htmlFor="user-password"
        ></img>
        <input
          disabled={disable}
          className={classes.input}
          type="password"
          id="user-password"
          name="user-password"
          autoComplete="off"
          placeholder="Password"
          ref={passwordInputRef}
          required={!context.isLoggedIn}
        ></input>
      </div>
      <button
        className={classes.loginBtn}
        disabled={context.isLoggedIn}
      >
        {context.isLoggedIn ? "Already logged in" : "Login"}
      </button>
    </form>
  );
}

export default LoginForm;
