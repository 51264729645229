import React,{ useRef,useEffect, useState,useContext} from 'react';
import Layout from "../../components/Layout";
import classes from "../../crud/sales/NewSales.module.scss";
import {UpdateSale, getClientDebts} from "../../Services/SalesServices";
import icon_delete from '../../assets/images/delete-icon.png';
import {StringToBinary, StringFromBinary} from "../../utils/Base64Utils"

import Button from "react-bootstrap/Button";
import { 
  validate_int,
  alphabetic_with_space, 
  valid_len, 
  validate_number_non_zero,
  validate_alphanumeric_spaces_symbols,
  validate_int_greater_zero,
  validate_alphanumeric_spaces_more_symbols } from '../../utils/validator';

import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';

import {useParams} from "react-router-dom";

import AuthContext from '../../Context/AuthContext';

function EditSales(){

    const context = useContext(AuthContext);
    const TIMEOT_SESSION_CLOSES = 3000;

    function fn_parser_date_format( data ){
      var d_arr = data.split("-");
      var newdate = d_arr[2] + '-' + d_arr[1] + '-' + d_arr[0];
      return newdate;
    }

                                                            // gets client data
    const {sale} = useParams();
    var client_sale_data = JSON.parse(StringFromBinary( sale ));
    const client_data = client_sale_data.client;
    const sale_data = client_sale_data.sale;
    console.log( sale_data )
    const navigate = useNavigate();
    const [clientEncoded, setClientEncoded] = useState(StringToBinary(JSON.stringify(client_data)));
    
    var client_surname = client_data.apellidonombre.substring(0,client_data.apellidonombre.indexOf(","));
    var client_name = client_data.apellidonombre.substring(client_data.apellidonombre.indexOf(",")+1,client_data.apellidonombre.length);
    console.log( 'name: ' +  client_name + ' - surname: ' + client_surname  );

    const [_name, setName] = useState(client_surname + ', '+ client_name);
    const [_document, setDocument] = useState(client_data.dni);    

    const form_name = _name;
    const form_document = _document;    
                                                            // sales date
    const [dateFrom, setDateFrom] = useState(fn_parser_date_format(sale_data.friendly_date));
    const [datesReOrdered, setDatesReOrdered] = useState(false);
    const [ sale_date,set_sale_date ] = useState(fn_parser_date_format(sale_data.friendly_date));
    const dateFromInputRef = useRef(null);
    const [error_dateFrom, setErrorDateFrom] = useState("");

    const [rest_pay, set_rest_pay] = useState( 0 );

    const handleChangeDateFrom = (e) => {
        setDateFrom(e.target.value);
        console.log( new Date(e.target.value) )
        set_sale_date(e.target.value)
      };
                                                              // disable
    const [disable, setDisable] = useState(false);
    const [disablePlan, setDisablePlan] = useState(false);
                                                              // inputs
    const [error_description, setErrorDescription] = useState("");
    const [description, setDescription] = useState(sale_data.description);

    const [error_cash, setErrorCash] = useState("");
    const [cash, setCash] = useState(sale_data.cash);

    const [error_parts, setErrorParts] = useState("");
    const [parts, setParts] = useState(sale_data.payment.length);


    const [paymentTypes, setPaymentTypes] = useState(
      [
        {'id':1,'name':'VENTAS CUERO'},
        {'id':0,'name':'VENTAS'},
        {'id':2,'name':'VENTAS OTROS'},
      ]
      );

    const [error_payMentPlanning, setErrorPayMentPlanning] = useState("");


                                                              // FE18122023
    const [clientDebts, setClientDebts] = useState([]);    


                                                                                    // set payment plan from sale objet
  /*****************************************************************************
   * set_payment_plan
   *****************************************************************************/

  function set_payment_plan ( data ) {
    
                                                  // valid data
      const _parts = [];
      for(let i = 0; i < data.length; i++){
          var paid = true;
          if( data[i].paid === '0')paid = false;
          const ob = {
            'id':i,
            'payment_id':+data[i].payment_id,
            'date':'',
            'value': data[i].value,
            'error_msg': '',
            'error_msg_date':'',
            'type':data[i].type,
            'error_msg_op':'',
            'timestamp':data[i].timestamp,
            'calendar_date':fn_parser_date_format(data[i].friendly_date),
            'paid':data[i].paid,
            'checked_paid': paid
          };
          _parts.push( ob );
      }
      console.log( _parts )
      return _parts;

    
  };



    const [payMentPlanning, setPayMentPlanning] = useState([]);


                                                              // updated: 26-09-2023: FE26092023-4
    function update_rest_pay()
    {
      var _rest_pay = 0;
      payMentPlanning.forEach( part => {
        //console.log( part )
        if( Number(part.paid) !== 0 )_rest_pay = _rest_pay + Number(part.value);
      });
      //console.log( _rest_pay )
      set_rest_pay( _rest_pay );
    }



  /*****************************************************************************
   * gets data from REST when page is loaded
   *****************************************************************************/

    useEffect (( ) => {

      setPayMentPlanning(set_payment_plan(sale_data.payment));
      update_rest_pay();
      update_client_debts();                                 // FE18122023

    }, [ ]);



     /** ***********************************************************************************
     * update_client_debts()
    ***************************************************************************************/
                                                            // FE18122023
    function update_client_debts()
    {
        const ob = {
            id_cliente:client_data.id_cliente
        };

        getClientDebts( ob ).then(
            res =>{
                console.log( res )
                if( res.status === 'OK' ){


                    const _array = res.msg.client_debt;
                    
                    _array.forEach(
                        month => {month.total = Math.round((month.total + Number.EPSILON) * 100) / 100;}

                    );

                    setClientDebts( _array );
                }
                    
                
            }
        );
    }  



    const [dateTo, setDateTo] = useState('');
    const dateToInputRef = useRef(null);

    const handleChangeDatePlan = (e) => {
        
      };      

    const handlerBtn_cancel = event =>{
        
        navigate('/sales/'+clientEncoded);
      }

    

  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

 function validateData ( data, field ) {
    console.log( data )
    console.log( field )
    var _error = '';
    switch( field ){
      case 'desription':
        setDescription( data );
        if( !valid_len(data,2,50)) _error = "Longitud no válida";
        if( !validate_alphanumeric_spaces_more_symbols( data ))_error = "Existen caracteres no válidos";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorDescription( _error );        
        break;        

        case 'cash':
          setCash( data );
          if( !validate_number_non_zero( data ))_error = "Existen caracteres no válidos";
          if( data.length === 0 )_error = "Campo obligatorio";
          
          setErrorCash( _error );        
          break;   

        case 'cashList':
                                                                          //19-09-2023: Allow payment zero input
            if( !validate_int( data ))_error = "Existen caracteres no válidos";
            if( data.length === 0 )_error = "Campo obligatorio";
            break;          

        case 'parts':
          setParts( data );
          if( !validate_int_greater_zero( data ))_error = "Existen caracteres no válidos";
          if( data.length === 0 )_error = "Campo obligatorio";
          else if( data === '0' )_error = "No puede ser cero";
          setErrorParts( _error );        
          break;  
          
        case 'dateFrom':
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorDateFrom( _error );        
          break;  
          
          

    }
    console.log( _error )
    return _error;
  }


  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handlePaymentPlaning = event => {
    var i = 0;
    var _arr = [];
    payMentPlanning.forEach( pay =>{
      _arr.push( pay );
      i++;
    });


    const ob = {
      'id':i,
      'payment_id':0,
      'date':'',
      'value': 0,
      'error_msg': 'Campo obligatorio',
      'error_msg_date':'Campo obligatorio',
      'error_msg_op':'',
      'type':'VENTAS CUERO',
      'timestamp':0,
      'paid':0,
      'checked_paid': false,  
      'calendar_date':'',
    };
    _arr.push( ob );
    
    setParts( _arr.length );    
    setPayMentPlanning(_arr);
    update_rest_pay();
    
  };





  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handleChange = event => {
    validateData(event.target.value, event.target.id)
  };



  function getDate( data ){
    var now = new Date( +data );        
    var year = now.getUTCFullYear();
    var month = now.getUTCMonth() + 1;
    if( month < 10 )month = '0'+month;
    var day = now.getUTCDate();
    if( day < 10 )day = '0'+day;
    return day + '-'+month +'-'+year;
}



  const handleListChange = event => {
    //validateData(event.target.value, event.target.id)
   
    var element_id = event.target.id;
    var element_name = event.target.name;
    var element_value = event.target.value;
    console.log(element_id)
    var err =  validateData(element_value, element_name);
    var _arr = [];
    payMentPlanning.forEach( pay =>{
      if( pay.id === Number(element_id)){
        console.log('++')
        switch( element_name ){
          case 'cashList':
            pay.error_msg = err;
            pay.value = element_value;
            break;
          case 'type':
            pay.error_msg_op = err;
            //pay.value = element_value;
            break;
        }
        
      }
      _arr.push( pay );
    });
    var _arr_2 = [];    
    if( event.target.type === 'date'  ){
      console.log( element_value )
        if(!datesReOrdered)
        {
                                            // order by dates (timesamp )
          _arr.forEach(
              e =>{
                
                if(e.id === Number(element_id) )
                {
                  if( element_value.length !== 0 )
                  {
                    const time = getUTCfromLocal(new Date(element_value));
                    e.timestamp = ""+time;
                    e.calendar_date=fn_parser_date_format(getDate(e.timestamp));
                    e.error_msg_date='';
                  }
                  else{
                    e.timestamp = 0;
                    e.calendar_date = '';
                    e.error_msg_date = 'Campo obligatorio';
                  }
                }
              });


        if( element_value.length !== 0 ){
        
          var ptr = 0;
          while( _arr.length > 0 ){
          
            var minor = Number(_arr[0].timestamp);
            var minor_index = 0;
            ptr = 0;
            _arr.forEach(
              e =>{
                //console.log( Number(e.timestamp) + " ----- " + minor )
                if(Number(e.timestamp) < minor ){
                  
                  minor = Number(e.timestamp);
                  minor_index = ptr;
                }
                ptr++;
              }
            );
            const ob = structuredClone(_arr[minor_index]);
            _arr_2.push( ob );
          
            _arr.splice(minor_index,1);
            
          }

          ptr = 0;
          var wasAltered = false;
          _arr_2.forEach(
            e =>{
              if( ptr !== e.id ){
                wasAltered = true;
                setDatesReOrdered(true);
              }
              e.id = ptr;
              //console.log( e.id + ' ** ' + ptr + " altered: " + wasAltered )
              _arr.push(e);
              ptr++;
            });        
        }
      }else setDatesReOrdered( false )

    }
    console.log( _arr )
    setPayMentPlanning( _arr );
    update_rest_pay();

    //payMentPlanning[element_id].error_msg = err;

    //console.log( _arr )
  };

  function getUTCfromLocal( dateFrom ){
    return (new Date(dateFrom)).getTime();
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  // for radio buttons

  const handleRadioListChange = ev =>{
    console.log( ev )

    var _arr = [];
    payMentPlanning.forEach( pay =>{
      if( pay.id === Number(ev.target.id)){
          pay.type = ev.target.value;       
      }
      _arr.push( pay );
    });
    console.log( _arr )
    setPayMentPlanning( _arr );
    update_rest_pay();
  }
                                                        // summit btn handler
  const handleSummitSales = ev =>{
    var isValid = true;

    payMentPlanning.forEach( pay =>{
      if( 
        pay.error_msg_date || pay.error_msg 
        )isValid = false;
    });

    const ob_sale = {
      'description':description,
      'cash':cash,
      'pays_number':parts,
      'timestamp':getUTCfromLocal(dateFrom),
      'payment':payMentPlanning,
      'client':client_data,
      'sales_id':Number(sale_data.sales_id)
    }

    console.log( ob_sale );
    console.log( isValid );
    if(isValid){
                                                        // creates sales
      async function updateSale( data )
      {
        UpdateSale(data).then(
          async res => {
            if(res.status === 'OK'){
              Swal.fire({
                title: 'Venta modificada',
                text: "Los datos de la venta se modificaron correctamente",
                icon: 'success',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
              });
              await timeout(2000);
              //rstClient();
              navigate('/sales/'+clientEncoded);

            }
            else{

              Swal.fire({
                title: 'Se produjo un error',
                text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                icon: 'error',
                timer: TIMEOT_SESSION_CLOSES,
                showCancelButton: false,
                showConfirmButton: false                
              });
              await timeout( TIMEOT_SESSION_CLOSES );
              context.logoutUser();
              navigate('/');

            }
            //setDisable( false );                
          }          
        );
      }
      updateSale( ob_sale );
                          
    }
  }

    const handlePaydChange = ev =>{
      console.log( ev );
      const _arr = []
      payMentPlanning.forEach( pay =>{
       
        if( pay.id === Number(ev.target.id) && ev.type === 'click')
        {
          if( pay.paid === '0' ){
            pay.paid = '1';
            pay.checked_paid = true;
          }
          else {
            pay.paid = '0';
            pay.checked_paid = false;
          }
        }
        _arr.push( pay )
      });
      setPayMentPlanning(_arr)
      update_rest_pay();
    }


    const handleDeletePay = ev =>{
      var _arr = [];
      var _id = 0;
      console.log( ev.target.id )
      payMentPlanning.forEach( pay =>{
        if( pay.id !== Number(ev.target.id))
        {
          pay.id = _id;
          _arr.push( pay );
          _id++;
        }
      });
      console.log( _arr )
      setParts( _arr.length );
      setPayMentPlanning(_arr);
      update_rest_pay();
    }

    const foo = event => {
    //validateData(event.target.value, event.target.id)
   
    var element_id = event.target.id;
    var element_name = event.target.name;
    var element_value = event.target.value;
    console.log(element_id)
    var err =  validateData(element_value, element_name);
    var _arr = [];
    payMentPlanning.forEach( pay =>{
      if( pay.id === Number(element_id)){
        console.log('++')
        switch( element_name ){
          case 'cashList':
            pay.error_msg = err;
            pay.value = element_value;
            break;
          case 'type':
            pay.error_msg_op = err;
            //pay.value = element_value;
            break;
        }
        
      }
      _arr.push( pay );
    });
    
    if( event.target.type === 'date'  ){
      
        
        {
                                            // order by dates (timesamp )
          _arr.forEach(
              e =>{
                console.log( e )
                if(e.id === Number(element_id) )
                {
                  if( element_value.length !== 0 )
                  {
                    var _year = 2021;
                    try{
                      const _year_temp = Number(element_value.substring(0,4));
                      if( _year_temp > _year )_year = _year_temp;
                    }catch(e){}
                    _year = _year +'-'+element_value.substring(5)
                    console.log(_year)
                    const time = getUTCfromLocal(new Date(_year));
                    e.timestamp = ""+time;
                    e.calendar_date=fn_parser_date_format(getDate(e.timestamp));
                    e.error_msg_date='';
                  }
                }
              });
        }
      

    }
    console.log( _arr )
    setPayMentPlanning( _arr );
    update_rest_pay();

    //payMentPlanning[element_id].error_msg = err;

    //console.log( _arr )      
    }


    return(
        <Layout>
            <div className="card" style={{width: '65vw'}}>
                <div className="card-header bg-primary">Nueva venta</div> 
                    <div className="card-body col-lg">

                        <div className='d-flex'>
                            <div className="col-2 text-dark">Cliente:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input value={form_name} disabled={true} className="form-control"  type="text" id="name" name="name" />
                                </div>                
                            </div >  
                        
                            <div className="col-2 text-dark">Documento:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input  value={form_document} disabled={true} className="form-control"  type="text" id="document" name="document"/>
                                </div>                                
                            </div >   
                        </div>

                        <div className="col-sm"></div > 

                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Resumen de deuda mensual</div >   
                        </div > 

                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>
                                <div  style={{width: '60vw',overflowX:'scroll',height:'10vh'}}>
                                    <div className="d-flex">
                                    {
                                        
                                        clientDebts.map( deb => {
                                            return <div style={{width: '20vw'}}  key={deb.month_year} >
                                                <div  className=" text-dark">{deb.month_year}</div>
                                                <div className=" text-secondary">{deb.total}</div>
                                            </div>
                                        })
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                        </div > 

                        <div className="col-sm" style={{height:'1vh'}}></div > 





                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Nueva venta</div >   
                        </div > 

                        <div className="col-lg">
                            <div className={classes.new_sale_container}>
                                <div className='d-flex '>
                                    <div className="col-2 text-dark">
                                        Descripción:   
                                    </div >

                                    <div className="col-4">
                                      <div className={classes.inputTextContainer}>
                                          <input value={description} disabled={disable} className="form-control"  type="text" id="desription" name="desription" onChange={handleChange} onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_description}</div>
                                      </div>                                
                                    </div >  

                                    <div className="col-1 text-dark">
                                        Fecha:   
                                    </div >    
                                    
                                    <div className="col-4">
                                            <div>
                                                <input
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateFrom}
                                                    ref={dateFromInputRef}
                                                    disabled={disable}
                                                    value={sale_date}
                                                />
                                                 <div className="text-danger h6">{error_dateFrom}</div>
                                            </div>
                                    </div > 

                                </div>

                                <div className='d-flex '>
                                    <div className="col-2 text-dark">
                                        Importe:   
                                    </div >

                                    <div className="col-2">
                                      <div className={classes.inputTextContainer}>
                                          <input  value={cash} disabled={disable} className="form-control"  type="text" id="cash" name="cash" onChange={handleChange} onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_cash}</div>
                                      </div>                                
                                    </div >

                                    <div className="col-1 text-dark">
                                        Cuotas:   
                                    </div >

                                    <div className="col-1">
                                      <div className={classes.inputTextContainer}>
                                          <input disabled={true} value={parts} className="form-control"  type="text" id="parts" name="parts" onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_parts}</div>
                                      </div>                                
                                    </div >

                                    <div className="col-1 text-dark">
                                        Saldo:   
                                    </div >

                                    <div className="col-2">
                                      <div className={classes.inputTextContainer}>
                                          <input disabled={true} value={cash - rest_pay } className="form-control"  type="text" id="rest_pay"/>
                                          <div className="text-danger h6">{error_parts}</div>
                                      </div>                                
                                    </div >                                    

                                    <div className="col-3 text-dark">
                                        <Button  className="btn btn-success"  onClick={handlePaymentPlaning}>Nuevo pago</Button>
                                    </div >  

                                </div>
                            </div>    
                        </div >   

                        
                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Agenda de pagos</div >   
                        </div >                          

                        <div className="col-lg">
                            <div className='d-flex'>
                                <div className="col-1 text-dark">
                                    Pagado
                                </div >                              
                                <div className="col-2 text-dark">
                                    Importe
                                </div >      
                                <div className="col-1 text-dark">
                                    
                                </div >                                                                                              
                                <div className="col-3 text-dark">
                                    Vencimiento
                                </div >      
                                <div className="col-1 text-dark"></div >                                                          
                                <div className="col-2 text-dark">
                                    Forma de pago
                                </div > 
                                <div className="col-2 text-dark">
                                    Eliminar
                                </div >                                                                                                                              
                            </div >                              
                            <div className={classes.listContainer}>
                            {
                              payMentPlanning.map(
                                part =>{
                                  return <div className='d-flex ' key={part.id}>
                                    <div className="col-1">
                                      <div className={classes.colId}>
                                          <input type="checkbox" 
                                            checked={ payMentPlanning[part.id].checked_paid }
                                            id={part.id}
                                            onChange={handlePaydChange} 
                                            onClick={handlePaydChange}
                                            disabled={disablePlan} 
                                            />
                                      </div>
                                        
                                    </div >                              
                                    <div className="col-2 text-dark">
                                        <div >
                                          <input  disabled={disablePlan} 
                                            className="form-control"  
                                            type="text" 
                                            id={part.id}
                                            value={payMentPlanning[part.id].value}
                                            name="cashList" 
                                            onChange={handleListChange} 
                                            onBlur={handleListChange}/>
                                            <div className="text-danger h6">{payMentPlanning[part.id].error_msg}</div>
                                        </div>  
                                      </div >                                                              
                                      <div className="col-1 text-dark"></div >                          
                                    <div className="col-3 text-dark">
                                      <input
                                            className="text-dark"
                                            type="date"
                                            value={payMentPlanning[part.id].calendar_date}
                                            onChange={foo}
                                            onBlur={handleListChange}
                                            disabled={disablePlan}
                                            id={part.id} 
                                        />                                        
                                      <div className="text-danger h6">{payMentPlanning[part.id].error_msg_date}</div>
                                    </div >      
                                    <div className="col-1 text-dark"></div >                          
                                    <div className="col-2 text-dark">
                                      <div  className={classes.listContainerOptions}>
                                        {
                                          paymentTypes.map(
                                            type =>{
                                              return <div className='text-dark' style={{display:'flex'}} key={type.id}>
                                                <div style={{width:'20px'}}>
                                                  <input 
                                                    disabled={disablePlan} 
                                                    type="radio" 
                                                    value={type.name} 
                                                    name={part.id}
                                                    id={part.id}
                                                    checked = { type.name === payMentPlanning[part.id].type }
                                                    onChange={handleRadioListChange}                                             
                                                     />
                                                </div>
                                                {type.name}
                                                </div>
                                            })
                                        }
                                      </div>

                                    </div >  
                                    <div className="col-2 text-dark text-center">
                                      <div title="Eliminar cliente">
                                         <img  onClick={handleDeletePay} id={part.id} className={classes.icon} src={icon_delete}/>
                                      </div>                                    
                                    </div >                                                                            
                                  </div >  
                                }
                              )
                            }
                            </div>
                        </div>                            

                        <div className="col-sm">
                            <div className='d-flex'>
                                <div className="col-3 text-dark"></div>
                                <div className="col-3 text-dark">
                                   <Button  className="btn btn-success"  onClick={handleSummitSales}>Confirmar operación</Button>
                                </div > 
                                <div className="col-3"><Button  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                                <div className="col-3 text-dark"></div>
                            </div >   
                        </div >   
                        <div className="col-sm"></div >   
                        </div >                           
                    </div>                    
                    <div className={classes.spacer}></div>
            
        </Layout>    
    );
}

export default EditSales