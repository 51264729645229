import instance from "../Config/axios";

/* -------------------------------------------------------------------------------------------------
loginUser: logins an user.
--------------------------------------------------------------------------------------------------*/
                                                                                // login user
export async function loginUser(user){
    
    var query = {
        status:'error',
        msg:"Credenciales no válidas"
    };
    try{
        await instance.post(  `/user.php?login`, user)
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.message;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}

/* -------------------------------------------------------------------------------------------------
loginUser: logins an user.
--------------------------------------------------------------------------------------------------*/
                                                                                // login user
export async function getUserData(){
    
    var query = {
        status:'error',
        msg:"Token no válido"
    };
    try{
        await instance.get(  `/user.php?user_info`)
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.user_data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}