/*****************************************************************************
 * orderUtils
*****************************************************************************/


/*****************************************************************************
 * getClientsList
*****************************************************************************/
function getClientsList( data )
{
    const ret = [];
    
                                                                // gets uniques

    data.forEach(element => {
        var isInArr = false;
        ret.forEach(_el =>{
            if(element.id_cliente === _el.id_cliente ) isInArr = true;
        });
        if( !isInArr ){
            const _ob = { 
                apellidonombre: element.apellidonombre,
                id_cliente:element.id_cliente
             };
            let arr = _ob.apellidonombre.split(',');
            if( arr.length > 0 )_ob.lastname = arr[0];
            if( arr.length > 1 )_ob.name = arr[1].substr(1);
            ret.push( _ob );
        }
    });

                                                                // order list alphabetically
    ret.sort(function(a, b){
        if(a.apellidonombre < b.apellidonombre) { return -1; }
        if(a.apellidonombre > b.apellidonombre) { return 1; }
        return 0;
    });

    //console.log( ret )

    return ret;
}


/*****************************************************************************
 *
*****************************************************************************/






/*****************************************************************************
 * ExportExel: exports json data from sales viewer in an Excel file format
*****************************************************************************/

export function OrderSalesByClientNameAndDate( data )
{
    const ret = [];

    const clientList = getClientsList( data );                      // clients list ordered
                                                                    // sales ordered
    data.sort(function(a, b){
        if(a.timestamp < b.timestamp) { return -1; }
        if(a.timestamp > b.timestamp) { return 1; }
        return 0;
    });
    
    clientList.forEach( el => {
        data.forEach( _data => {
            if( el.id_cliente === _data.id_cliente ){
                ret.push({..._data})
            }
        });
    } );

    return ret;
}