import React,{ useEffect, useState,useContext} from 'react';
import Layout from "../../components/Layout";
import classes from "../../crud/clients/NewClient.module.scss";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { 
  validate_int,
  alphabetic_with_space, 
  valid_len, 
  validate_email,
  validate_alphanumeric_spaces_symbols,
  validate_alphanumeric_spaces_more_symbols } from '../../utils/validator';
import Swal from 'sweetalert2';
import {newClient} from "../../Services/ClientServices";
import { useNavigate } from 'react-router-dom';
                                                          // json data
import cities from  '../../assets/app_data/localidades.json'                                                          
import states from '../../assets/app_data/provincias.json'                                                          
import AuthContext from '../../Context/AuthContext';

function NewClient (){

  const context = useContext(AuthContext);
  const TIMEOT_SESSION_CLOSES = 3000;

  const default_place = {
    state:"Neuquén",
    city:"NEUQUEN"
  }

  const [_state, setState] = useState(default_place.state);
  const [_city, setCity]   = useState(default_place.city);

 

  //console.log( states )
  const navigate = useNavigate();
  const [error_name, setErrorName] = useState("");
  const [error_surname, setErrorSurName] = useState("");
  const [error_address, setErrorAddress] = useState("");
  const [error_phone, setErrorPhone] = useState("");
  const [error_document, setErrorDocument] = useState("");
  const [error_email, setErrorEmail] = useState("");
  const [error_cbu1, setErrorCBU1] = useState("");
  const [error_cbu2, setErrorCBU2] = useState("");
  const [error_city, setErrorCity] = useState("");
  const [error_state, setErrorState] = useState("");
  const [error_boss, setErrorBoss] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => setIsChecked(!isChecked);

  const [_name, setName] = useState("");
  const [_surname, setSurName] = useState("");
  const [_address, setAddress] = useState("");
  const [_phone, setPhone] = useState("");
  const [_document, setDocument] = useState("");
  const [_email, setEmail] = useState("");
  const [_cbu1, setCBU1] = useState("");
  const [_cbu2, setCBU2] = useState("");


  const [_cities, setCities] = useState([{checked:false,nombre:'',id:0}]);
  const [_states, setStates] = useState([{checked:false,nombre:'',id:0}]);
  const [_boss, setBoss] = useState("");



                                                  // added for call once setting state and city: (FE2592023-01)

  function prepareDefaultStateCity( stateName, cityName )
  {
    
      states.provincias.forEach( element=>{
        element.selected = false;
        if( element.nombre === stateName )element.selected = true;
      }    
    );
    setStates(states.provincias);
    var stateCities = [];
    cities.localidades.forEach(element => {
      element.selected = false;
      if( element.provincia.nombre === stateName ){
        if( element.nombre === cityName )element.selected= true;
        stateCities.push( element );
      }
    });
    setCities(stateCities);  

  }


                                                  // added for call once setting state and city
  useEffect(
    () => {
      prepareDefaultStateCity(default_place.state,default_place.city);
    },[]
  );
  

                                                      // disables fields and commands
  const [disable, setDisable] = useState(false);

  
  function validateData ( data, field ) {
    console.log( data )
    console.log( field )
    var _error = '';
    switch( field ){
      case 'name':
        setName( data );
        if( !valid_len(data,3,20)) _error = "Longitud no válida";
        if( !alphabetic_with_space(data) ) _error = "Ingrese letras o espacios";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorName( _error );
        break;

      case 'surname':
        setSurName( data );
        if( !valid_len(data,3,20)) _error = "Longitud no válida";
        if( !alphabetic_with_space(data) ) _error = "Ingrese letras o espacios";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorSurName( _error );  
        break;

      case 'document':
        setDocument( data );
        if( !valid_len(data,6,10)) _error = "Longitud no válida";
        if( !validate_int(data) ) _error = "Ingrese números";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorDocument( _error );
        break;      

      case 'address':
        setAddress( data );
        if( !valid_len(data,2,50)) _error = "Longitud no válida";
        if( !validate_alphanumeric_spaces_more_symbols( data ))_error = "Existen caracteres no válidos";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorAddress( _error );        
        break;                          

      case 'phone':
        setPhone( data );
        if( !valid_len(data,5,20)) _error = "Longitud no válida";
        if( !validate_int(data) ) _error = "Ingrese números";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorPhone( _error );
        break;     


                                                              //19-09-2023: email validation disabled
      case 'email':
        setEmail( data );
        /*
        if( !valid_len(data,5,128)) _error = "Longitud no válida";
        if( !validate_email(data) ) _error = "Email no válido";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorEmail( _error );        
        */
        break;     

      case 'cbu1':
        setCBU1( data );
        if( !valid_len(data,7,9)) _error = "Longitud no válida";
        if( !validate_int(data) ) _error = "Ingrese números";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorCBU1( _error );
        break;     

      case 'cbu2':
        setCBU2( data );
        if( !valid_len(data,13,15)) _error = "Longitud no válida";
        if( !validate_int(data) ) _error = "Ingrese números";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorCBU2( _error );
        break;
        
      case 'city' :
        setCity( data );
        if( !valid_len(data,2,50) || !validate_alphanumeric_spaces_more_symbols( data ) ) _error = "Ciudad no válida";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorCity( _error );        
        break;

      case 'state':
        setState( data );
        if( !valid_len(data,2,50) || !validate_alphanumeric_spaces_more_symbols( data )) _error = "Provincia no válida";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorState( _error );                
        break;

        case 'boss':
          setBoss( data );
          if( !valid_len(data,3,20)) _error = "Longitud no válida";
          if( !validate_alphanumeric_spaces_symbols( data ) ) _error = "Ingrese letras, números, () o espacios";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorBoss( _error );
          break;        
    }
    console.log( _error )
    return _error;
  }

  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handleChange = event => {
    validateData(event.target.value, event.target.id)
  };


  /*****************************************************************************
   * rstClient: 
   *****************************************************************************/
  function rstClient(){
    const data = '';
    setName( data );
    setSurName( data );
    setDocument( data );
    setAddress( data );
    setPhone( data );
    setEmail( data );
    setCBU1( data );
    setCBU2( data );
    setCity( data );
    setState( data );
    setBoss( data );
    prepareDefaultStateCity(default_place.state,default_place.city);
  }


  const handlerBtn_cancel = event =>{
    rstClient();
    navigate('/clients');
  }



  /*****************************************************************************
   * handleStateSelect: updates selected state and updates cities for that state 
   *****************************************************************************/  

  const handleStateSelect = event =>{
    //console.log( event.target.value )
    setState( event.target.value );
    var stateCities = [];
    cities.localidades.forEach(element => {
      if( element.provincia.nombre === event.target.value ){
        stateCities.push( element );
      }
    });
    setCities( stateCities );
  }

  /*****************************************************************************
   * handleStateChange: updates selected state (checked only) - 26-09-2023: (FE2592023-02)
   *****************************************************************************/  
  const handleStateChange = event =>{
      
      _states.forEach(element => {
        element.selected = false;
        if( element.nombre === event.target.value ){
          element.selected = true;
          
        }

        
      });

      //setStates( updated_states );
  }


  /*****************************************************************************
   * handleCitySelect: updates selected state and updates cities for that state 
   *****************************************************************************/  

  const handleCitySelect = event =>{
    console.log( event.target.value )
    setCity( event.target.value );
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }


  /*****************************************************************************
   * handleCityChange: updates selected state (checked only) - 26-09-2023: (FE2592023-03)
   *****************************************************************************/  
  const handleCityChange = event =>{
    
    _cities.forEach(element => {
      element.selected = false;
      if( element.nombre === event.target.value ){
        element.selected = true;
        
      }
    });
    //setCities( updated_states );
}




  /*****************************************************************************
   * handlerBtn_newClient: 
   *****************************************************************************/

  const handlerBtn_newClient = event =>{
    
    if(
        validateData(_name,     'name' )     === '' &&
        validateData(_surname,  'surname' )  === '' &&
        validateData(_address,  'address' )  === '' &&
        validateData(_phone,    'phone' )    === '' &&
        validateData(_document, 'document' ) === '' &&
                                                                          // disables email validation
        //validateData(_email,    'email' )    === '' &&
        validateData(_cbu1,     'cbu1' )     === '' &&
        validateData(_cbu2,     'cbu2' )     === '' &&
        validateData(_state,    'state' )    === '' &&
        validateData(_city,     'city' )     === '' &&
        validateData(_boss,     'boss' )     === '' 
        )
      {
          setDisable( true );                                              // valid data
          async function createClient( data )
          {
            newClient( data ).then(
              async res => {
                if(res.status === 'OK'){
                  Swal.fire({
                    title: 'Cliente creado',
                    text: "El cliente se creo correctamente",
                    icon: 'success',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                  });
                  await timeout(2000);
                  rstClient();
                  navigate('/clients');

                }
                else{
                  if( res.close_session )
                  {
                    Swal.fire({
                      title: 'Se produjo un error',
                      text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                      icon: 'error',
                      timer: TIMEOT_SESSION_CLOSES,
                      showCancelButton: false,
                      showConfirmButton: false                
                    });
                    await timeout( TIMEOT_SESSION_CLOSES );
                    context.logoutUser();
                    navigate('/');
                  }
                  else{
                    Swal.fire({
                      title: 'Se produjo un error',
                      text: 'Error: ' + res.msg,
                      icon: 'error',
                      showCancelButton: false,
                      showConfirmButton: true                
                    });                    
                  }  
                }
                setDisable( false );                
              }
            );
          } 
          const client = {
            'name':_name.trimStart(),
            'surname':_surname.trimStart(),
            'address':_address,
            'phone':_phone,
            'document':_document,
            'email':_email,
            'cbu1':_cbu1,
            'cbu2':_cbu2,
            'city':_city,
            'state':_state,
            'boss':_boss,
            'local_bank':isChecked
          }
          console.log( client )
          createClient( client );
      }
  }

return(
    <Layout>
        <div className="card" style={{width: '65vw'}}>
          <div className="card-header bg-primary">Alta de cliente</div> 
          <div className="card-body col-lg">

            <div className='d-flex'>
              <div className="col-2 text-dark">Nombre:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input disabled={disable} className="form-control"  type="text" id="name" name="name" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_name}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Apellido:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  disabled={disable} className="form-control"  type="text" id="surname" name="surname" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_surname}</div>
                </div>                                
              </div >   
            </div>


            <div className='d-flex'>
              <div className="col-2 text-dark">DNI:</div >   
              <div className="col-2">
                <div className={classes.inputTextContainer}>
                  <input  disabled={disable} className="form-control"  type="text" id="document" name="document" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_document}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Domicilio:</div >   
              <div className="col-6">
                <div className={classes.inputTextContainer}>
                    <input  disabled={disable} className="form-control"  type="text" id="address" name="address" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_address}</div>
                </div>                                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">Provincia:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <div className="border">
                      <div  className={classes.listContainer}>
                        {
                          _states.map(
                            state =>{
                              return <div className='text-dark' style={{display:'flex'}} key={state.id}>
                                <div style={{width:'20px'}}>
                                  <input checked={state.selected } onChange={handleStateChange} disabled={disable} type="radio" value={state.nombre} name="state" onClick={handleStateSelect}/>
                                </div>
                                {state.nombre}
                                </div>
                            })
                        }
                      </div>
                  </div>  
                  <div className="text-danger h6">{error_state}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Ciudad:</div >   
              <div className="col-4">
              <div className={classes.inputTextContainer}>
                  <div className="border">
                      <div  className={classes.listContainer}>
                        {
                          _cities.map(
                            city =>{
                              return <div className='text-dark' style={{display:'flex'}} key={city.id}>
                                <div style={{width:'20px'}}>
                                  <input checked={city.selected} onChange={handleCityChange} disabled={disable} type="radio" value={city.nombre} name="cities" onClick={handleCitySelect}/>
                                </div>
                                {city.nombre}
                                </div>
                            })
                        }
                      </div>
                    
                  </div>
                  <div className="text-danger h6">{error_city}</div>  
                </div>                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">Teléfono:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input disabled={disable}  className="form-control"  type="text" id="phone" name="phone" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_phone}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">Email:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  disabled={disable} className="form-control"  type="email" id="email" name="email" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_email}</div>
                </div>                                
              </div >   
            </div>


            <div className='d-flex'>
              <div className="col-2 text-dark">CBU(1):</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                  <input  disabled={disable} className="form-control"  type="text" id="cbu1" name="cbu1" onChange={handleChange} onBlur={handleChange}/>
                  <div className="text-danger h6">{error_cbu1}</div>
                </div>                
              </div >   
              <div className="col-2 text-dark">CBU(2):</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input  disabled={disable} className="form-control"  type="text" id="cbu2" name="cbu2" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_cbu2}</div>
                </div>                                
              </div >   
            </div>

            <div className='d-flex'>
              <div className="col-2 text-dark">CBU panco provincial:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                <div>
              
                <Form>
                  <Form.Check
                    type="switch"
                    id="toggle-switch"
                    label="Toggle"
                    checked={isChecked}
                    onChange={handleToggle}
                    disabled={disable}
                  />
                </Form>
                </div>
                  
                </div>                
              </div >   
              <div className="col-2 text-dark">Empleador:</div >   
              <div className="col-4">
                <div className={classes.inputTextContainer}>
                    <input disabled={disable}  className="form-control"  type="text" id="boss" name="boss" onChange={handleChange} onBlur={handleChange}/>
                    <div className="text-danger h6">{error_boss}</div>
                </div>                                
              </div >   
            </div>            

            <div className='d-flex'>
              <div className="col-sm"></div >   
              <div className="col-sm">
                <div className='d-flex'>
                  <div className="col-sm"><Button disabled={disable} className="btn btn-primary"   onClick={handlerBtn_newClient}>Crear cliente</Button></div>
                  <div className="col-sm"><Button disabled={disable}  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                </div >   
              </div >   
              <div className="col-sm"></div >   
            </div >   
          </div>
        </div>
        <div className='spacer'><br></br></div>
    </Layout>
  )
}


export default NewClient