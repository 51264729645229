import AuthProvider from './Context/AuthProvider';
import Public from './Routes/Public';


function App() {
  return (
    <AuthProvider>
        <Public />
    </AuthProvider>
  );
}

export default App;
