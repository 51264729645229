/* -------------------------------------------------------------------------------------------------
Clients srvices.
--------------------------------------------------------------------------------------------------*/

import instance from "../Config/axios";
import {Buffer} from 'buffer';
var FileSaver = require('file-saver');
Buffer.from('anything','base64');

/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function getFiles(){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        await instance.get(  `/files.php?get_files`
        ,{
            responseEncoding: 'utf8',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        }
        )
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.files;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    //console.log( query )
    return query
}




/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function deleteFile(id){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al eliminar el archivo: " + id
    };
    try{
        await instance.delete(  '/files.php?delete'+'&file='+id)
        .then(res => {
            //console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}

export async function downloadFile( file ) {
    
    const resource = '/files.php?download&file='+file;
    function axiosDownloadFile(url, fileName) {
        return instance({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => {
            const href = window.URL.createObjectURL(response.data);
      
            const anchorElement = document.createElement('a');
      
            anchorElement.href = href;
            anchorElement.download = fileName;
      
            document.body.appendChild(anchorElement);
            anchorElement.click();
      
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
          })
          .catch(error => {
            console.log('error: ', error);
          });
      }
      axiosDownloadFile(resource, file);
 }




