/* -------------------------------------------------------------------------------------------------
Clients srvices.
--------------------------------------------------------------------------------------------------*/

import instance from "../Config/axios";
import {Buffer} from 'buffer';
Buffer.from('anything','base64');



/* -------------------------------------------------------------------------------------------------
UpdateSale: 
--------------------------------------------------------------------------------------------------*/

export async function UpdateConfig(data){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al editar la venta."
    };
    try{
        let req = `/configurations.php?update`;
        await instance.put(  req, data )
        .then(res => {
            console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.message;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}

/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function getConfig(ob){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de ventas."
    };
    try{
        await instance.get(  `/configurations.php?view`
        ,{
            responseEncoding: 'utf8',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        }
        )
        .then(res => {
            try{console.log( res.headers["content-type"] )}
            catch(e){console.log(e)}
            
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    //console.log( query )
    return query
}


/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function deleteSale(id){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al eliminar la venta ID: " + id
    };
    try{
        await instance.delete(  '/sale.php?sale'+'&id='+id)
        .then(res => {
            //console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}


/* -------------------------------------------------------------------------------------------------
getAllSales: gets all sales in time interval.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function getAllSales(ob){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de ventas."
    };
    try{
        await instance.get(  `/sale.php?payments`+'&from='+ob.from+'&to='+ob.to
        ,{
            responseEncoding: 'utf8',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        }
        )
        .then(res => {
            try{console.log( res.headers["content-type"] )}
            catch(e){console.log(e)}
            
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    //console.log( query )
    return query
}


/* -------------------------------------------------------------------------------------------------
UpdatePayment: 
--------------------------------------------------------------------------------------------------*/

export async function UpdatePayment(data){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al editar el pago."
    };
    try{
        let req = `/sale.php?paid`;
        await instance.put(  req, data )
        .then(res => {
            console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}