export function StringFromBinary(encoded) {
    const binaryData = atob(encoded);
    const bytes = new Uint8Array(binaryData.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }
  
  
  
  export function StringToBinary(stringData) {
    const codeUnits = new Uint16Array(stringData.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = stringData.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }