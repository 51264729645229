import React,{Component} from 'react';
import LoginForm from "../components/LoginForm";
import LoginHead from "../components/LoginHead";
import Layout from "../components/Layout"


class Login extends Component{
   
  render(){
    console.log(this.props)
    return(
      <Layout>
        <LoginHead />
        <LoginForm />
      </Layout>
    )
  }
}

export default Login