import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


/*****************************************************************************
 * parse_data_to_excel
*****************************************************************************/
function parse_data_to_excel( data )
{
    const parsed = {
        sales_cuero:[],
        sales_ventas:[],
        sales_otros:[]
    };


    data.forEach(element => {


        var presented = 'No';
        var paid = 'No';
    
        if( element.checked_presented ) presented = 'Si';
        if( element.checked_paid ) paid = 'Si';

        const _ob = {
            id:0,
            apellidonombre: element.apellidonombre,
            CBU_1: element.CBU_1,
            CBU_2: element.CBU_2,
            dni: element.dni,
            fecha: element.friendly_date,
            venta: element.type,
            importe: element.value,
            pagado:paid,
            presentado:presented,
        }

        switch( element.type )
        {
            case 'VENTAS':
                _ob.id = parsed.sales_ventas.length+1;
                parsed.sales_ventas.push( _ob );
                break;

            case 'VENTAS CUERO':
                _ob.id = parsed.sales_cuero.length+1;
                parsed.sales_cuero.push( _ob );                
                break;        
                
            default:
                _ob.id = parsed.sales_otros.length+1;
                parsed.sales_otros.push( _ob );                                
        }

    });

    return parsed;
}



/*****************************************************************************
 * ExportExel: exports json data from sales viewer in an Excel file format
*****************************************************************************/

export function ExportExel( excelData, fileName )
{
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    async function exportToCSV(apiData, fileName){

        
        //const ws = XLSX.utils.json_to_sheet(apiData);
        //const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const worksheet_cuero  = XLSX.utils.json_to_sheet(apiData.sales_cuero);
        const worksheet_ventas = XLSX.utils.json_to_sheet(apiData.sales_ventas);
        const worksheet_otros  = XLSX.utils.json_to_sheet(apiData.sales_otros);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet_cuero, "Ventas Cuero");
        XLSX.utils.book_append_sheet(workbook, worksheet_ventas, "Ventas");
        XLSX.utils.book_append_sheet(workbook, worksheet_otros, "Ventas Otros");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };





      //console.log( parse_data_to_excel(excelData) )

      exportToCSV( parse_data_to_excel( excelData ) , fileName );
}