import React,{ useEffect, useState,useContext} from 'react';
import Layout from "../components/Layout";
import {getFiles, deleteFile,downloadFile} from "../Services/FilesServices";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import classes from "../components/Clients.module.scss";
import AuthContext from '../Context/AuthContext';

// coments
import icon_download from '../assets/images/download.png';
import icon_delete from '../assets/images/delete-icon.png';


function Files (){
  const [files,setFiles] = useState([]);
  const context = useContext(AuthContext);
  const TIMEOT_SESSION_CLOSES = 3000;

  
  const navigate = useNavigate();
  

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }



  const request = async() => {
    
    getFiles().then(
      async res => {
        console.log( res );            
        if(res.status === 'OK')
        {
          console.log( res.msg );
          setFiles( res.msg );
        }
        else{
          Swal.fire({
            title: 'Se produjo un error',
            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
            icon: 'error',
            timer: TIMEOT_SESSION_CLOSES,
            showCancelButton: false,
            showConfirmButton: false                
          });
          await timeout( TIMEOT_SESSION_CLOSES );
          context.logoutUser();
          navigate('/');
        }
      }
    );
  }


  useEffect(
    () => {
      request();
    },[]
  );



 const handleDelete = event =>{
  console.log( event.target.id );
  Swal.fire({
    title: 'Confirmar eliminación',
    text: 'Desea eliminar el archivo: ' + event.target.id + '?',
    icon: 'warning',
    confirmButtonText: 'Sí, eliminarlo!',
    cancelButtonText: "No, cancelar",
    showCancelButton: true,
  }).then(
    function(isConfirm) 
    {
      //console.log(isConfirm.isConfirmed)
      if(isConfirm.isConfirmed){
        console.log('delete in process.. ID: ' + event.target.id)
        deleteFile( event.target.id ).then(
          async res => {
            
            if(res.status === 'OK')
            {
              Swal.fire({
                title: 'Archivo eliminado',
                text: "El archivo se elimino correctamente",
                icon: 'success',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
              });
              await timeout(2000);
              request()
            }
            else{
              Swal.fire({
                title: 'Se produjo un error',
                text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                icon: 'error',
                timer: TIMEOT_SESSION_CLOSES,
                showCancelButton: false,
                showConfirmButton: false                
              });
              await timeout( TIMEOT_SESSION_CLOSES );
              context.logoutUser();
              navigate('/');
            }
          }
        );
      }
    }
  );

} 


const handleDownload = event =>{
  console.log( event.target.id );
  downloadFile( event.target.id );
}




  return(
    <Layout>
      
      <div className={classes.ClientsHeadList}>
        <div className={classes.ClientsHeadItems_5}>Archivo</div>
        <div className={classes.ClientsHeadItems_6}>Opción</div>
      </div>
       <div className={classes.ClientsBodyList}>
       
        {
            files.map(
              reg=>
              <div key={reg.file_name} className={classes.ClientResultLine}>
                <div className={classes.ClientsHeadItems_5}>{reg.file_name}</div>
                  
                  <div className={classes.ClientsOptions}>
                      <div>
                        <div title="Descargar">
                          <img onClick={handleDownload} id={reg.file_name} className={classes.logo} src={icon_download}/>
                        </div>
                      </div>

                      <div>
                        <div title="Eliminar Archio">
                          <img onClick={handleDelete} id={reg.file_name} className={classes.logo2} src={icon_delete}/>
                        </div>
                      </div>
                  </div>
              </div>
            )
          
        }
       
       </div>

    </Layout>
  )
}

export default Files