import React from 'react';
import {Link} from "react-router-dom";
import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
import AuthContext from '../Context/AuthContext';
import logo from '../assets/images/henry_logo.png';
import classes from "./Menu.module.scss";

var styling = {
  nav_text:{
    color: "white"
  },
  nav_dropdown:{
    color: "white",
    height: 5
  },
  nav_dropdown_item:{
    color: "black"
  },
  nav_hello:{
    color: "white",
    marginRight:10
  }
}

function Menu() {
    return (
      <AuthContext.Consumer>{ context=>
        context.userLogin && 
        <Navbar bg="dark" expand="lg" >
          <Navbar.Brand  className={classes.brand}><div><img className={classes.logo} src={logo}/></div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" >
            <Nav.Link style={styling.nav_text} as={Link} to="/clients" >Inicio</Nav.Link>
              {
                context.userLogin &&
                <>
                    <Nav.Link style={styling.nav_text} as={Link} to="/viewsales" >Visor de ventas</Nav.Link>
                    <Nav.Link style={styling.nav_text} as={Link} to="/files" >Archivos banco</Nav.Link>
                    <Nav.Link style={styling.nav_text} as={Link} to="/config" >Ajustes</Nav.Link>
                    <Nav.Link onClick={context.logoutUser} as={Link} to="/" style={styling.nav_text} >Salir</Nav.Link> 
                </>
              }
            </Nav>
          </Navbar.Collapse>
          {
            context.userLogin &&
            <div style={styling.nav_hello} >Hola {context.userInfo.user_name}</div>
            
          }
        </Navbar>
      }</AuthContext.Consumer>  
    ); 
}
export default Menu;
