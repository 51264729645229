import React,{useState}from 'react';
import classes from "./ClientsFinder.module.scss";
import Button from "react-bootstrap/Button";
import { validate_int, alphabetic_with_space_and_comma } from '../utils/validator';
import { useNavigate } from 'react-router-dom';


function ClientsFinder(props) {
  const [selected, setSelectedRadio] = useState('name');
  const { updateClients,clientsNextPage } = props;
  const INPUT_DELAY = 500;
  var last_input_time = 0;
  const [error, setError] = useState("");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  function btnHandlerNexPage()
  {
    console.log('Next page handler click');
    const ob = { 'from':0,'isUpper':true };
    clientsNextPage( ob );
  }

  function btnHandlerPrevPage()
  {
    console.log('Prev page handler click');
    const ob = { 'from':0,'isUpper':false };
    clientsNextPage( ob );
  }


  function getCurrentTimestamp() {
    return Date.now();
  }

  function get_clients( data, source ){
 
    setTimeout(function() {
                                                // your code to be executed after 500 milliseconds
      if( last_input_time + INPUT_DELAY < 
        getCurrentTimestamp() ){
          const ob = { 'data':data,'source':source };
          updateClients( ob );
        }
    }, INPUT_DELAY);
  }

  const handleChange = event => {
    setSelectedRadio(event.target.value);
    last_input_time = getCurrentTimestamp();
    setError("");
    get_clients( null , null);
    setInputValue("");
  };

                                                // this implements functionability for search data after
                                                // a delay after last input

  const handleClientChange = event => {
    var validInput = false;
    setInputValue( event.target.value );
    try{
      if( event.target.value.length > 2 )
      {
                                                // if input string is greater than 2 then looks in db
                                                // apply format validations
        setError("");
        if( selected === 'doc' )
        {
          if( validate_int(event.target.value) )validInput = true;
          else setError("Ingrese solo números");
        }
        else{
          if( alphabetic_with_space_and_comma(event.target.value) )validInput = true;
          else setError("Ingrese solo letras o coma");
        }
        if(validInput)
        {
          last_input_time = getCurrentTimestamp();
          get_clients( event.target.value , selected);
        }

      }
      else if( event.target.value.length === 0 ){
        last_input_time = getCurrentTimestamp();
        setError("");
        get_clients( null , null);
      }
    }catch(e){
      console.log( e );
    }
  };
  

  function newClient(){
    console.log( 'NC' );
    navigate('/newclient');
  }

    return (
        <div className={classes.ClientsFinder}>
          <div className={classes.FinderOptions}>
            <div className={classes.FinderField_1} >Búsqueda de clientes por:</div>
            <div className={classes.FinderField_2} >
                <input className={classes.inputRadio} checked={selected ===  'doc'}  onChange={handleChange} type="radio" value="doc" name="finderoption" /> Documento
                <input className={classes.inputRadio} checked={selected === 'name'}  onChange={handleChange} type="radio" value="name" name="finderoption" /> Nombre y apellido
            </div>
          </div> 

          <div className={classes.FinderCommands}>
            <div className={classes.inputTextContainer}>
              <input value={inputValue} className="form-control"  type="text" id="lname" name="lname" onChange={handleClientChange}/>
              <div className="text-danger">{error}</div>
            </div>
         
            <div  className={classes.navClientsBtn}>
              <div className='row d-flex'>
              <div className="col-sm"><Button   className='btn btn-warning' onClick={btnHandlerPrevPage}>Previos</Button></div>
                <div className="col-sm"><Button className='btn btn-warning' onClick={btnHandlerNexPage}>Próximos</Button></div>
              </div>
            </div>         
         
          </div>

          <div className='row d-flex'>
              <div className="col-sm"><p style={{marginLeft:1+'px'}}>¿Necesita dar de alta un cliente?</p></div>
              <div className="col-sm"><Button className='btn' onClick={newClient}>Nuevo cliente</Button></div>
              <div className="col-sm"></div>
          </div>

        </div>  
    ); 
}
export default ClientsFinder;
