import React,{ useRef,useEffect, useState,useContext} from 'react';
import Layout from "../../components/Layout";
import classes from "../../crud/sales/NewSales.module.scss";
import {NewSale, getClientDebts} from "../../Services/SalesServices";
import AuthContext from '../../Context/AuthContext';
import {StringToBinary, StringFromBinary} from "../../utils/Base64Utils"

import Button from "react-bootstrap/Button";
import { 
  validate_int,
  alphabetic_with_space, 
  valid_len, 
  validate_number_non_zero,
  validate_alphanumeric_spaces_symbols,
  validate_int_greater_zero,
  validate_alphanumeric_spaces_more_symbols } from '../../utils/validator';


import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';

import {useParams} from "react-router-dom";

function NewSales(){
    const context = useContext(AuthContext);
    const TIMEOT_SESSION_CLOSES = 3000;
                                                            // gets client data
    const {client} = useParams();
    var client_data = JSON.parse(StringFromBinary( client ));
    const navigate = useNavigate();
    const [clientEncoded, setClientEncoded] = useState(client);
    var client_data = JSON.parse(StringFromBinary( client ));
    var client_surname = client_data.apellidonombre.substring(0,client_data.apellidonombre.indexOf(","));
    var client_name = client_data.apellidonombre.substring(client_data.apellidonombre.indexOf(",")+1,client_data.apellidonombre.length);
    console.log( 'name: ' +  client_name + ' - surname: ' + client_surname  );

    const [_name, setName] = useState(client_surname + ', '+ client_name);
    const [_document, setDocument] = useState(client_data.dni);    

    const form_name = _name;
    const form_document = _document;    
                                                            // sales date
    const [dateFrom, setDateFrom] = useState('');
    const dateFromInputRef = useRef(null);
    const [error_dateFrom, setErrorDateFrom] = useState("");

    const handleChangeDateFrom = (e) => {
        setDateFrom(e.target.value);
      };
                                                              // disable
    const [disable, setDisable] = useState(false);
    const [disablePlan, setDisablePlan] = useState(true);
                                                              // inputs
    const [error_description, setErrorDescription] = useState("");
    const [description, setDescription] = useState("");

    const [error_cash, setErrorCash] = useState("");
    const [cash, setCash] = useState("");

    const [error_parts, setErrorParts] = useState("");
    const [parts, setParts] = useState("");

                                                              // FE18122023
    const [clientDebts, setClientDebts] = useState([]);

    const [paymentTypes, setPaymentTypes] = useState(
      [
        {'id':1,'name':'VENTAS CUERO'},
        {'id':0,'name':'VENTAS'},
        {'id':2,'name':'VENTAS OTROS'},
      ]
      );

    const [error_payMentPlanning, setErrorPayMentPlanning] = useState("");
    const [payMentPlanning, setPayMentPlanning] = useState([]);



    const [dateTo, setDateTo] = useState('');
    const dateToInputRef = useRef(null);

    const handleChangeDatePlan = (e) => {
        
      };      

    const handlerBtn_cancel = event =>{
        
        navigate('/sales/'+clientEncoded);
      }

    

                                                              // updated: 26-09-2023: FE26092023-5
    const [rest_pay, set_rest_pay] = useState( 0 );                                                              

    function update_rest_pay()
    {
      var _rest_pay = 0;
      payMentPlanning.forEach( part => {
        //console.log( part )
        if( Number(part.paid) !== 0 )_rest_pay = _rest_pay + Number(part.value);
      });
      //console.log( _rest_pay )
      set_rest_pay( _rest_pay );
    }


     /** ***********************************************************************************
     * update_client_debts()
    ***************************************************************************************/
                                                            // FE18122023
    function update_client_debts()
    {
        const ob = {
            id_cliente:client_data.id_cliente
        };

        getClientDebts( ob ).then(
            res =>{
                console.log( res )
                if( res.status === 'OK' ){


                    const _array = res.msg.client_debt;
                    
                    _array.forEach(
                        month => {month.total = Math.round((month.total + Number.EPSILON) * 100) / 100;}

                    );

                    setClientDebts( _array );
                }
                    
                
            }
        );
    }  


  /*****************************************************************************
   * gets data from REST when page is loaded
   *****************************************************************************/

    useEffect (( ) => {
      update_client_debts();
    }, [ ]);



  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

 function validateData ( data, field ) {
    console.log( data )
    console.log( field )
    var _error = '';
    switch( field ){
      case 'desription':
        setDescription( data );
        if( !valid_len(data,2,50)) _error = "Longitud no válida";
        if( !validate_alphanumeric_spaces_more_symbols( data ))_error = "Existen caracteres no válidos";
        if( data.length === 0 )_error = "Campo obligatorio";
        setErrorDescription( _error );        
        break;        

        case 'cash':
          setCash( data );
                                                                
          
          if( !validate_number_non_zero( data ))_error = "Existen caracteres no válidos";
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorCash( _error );        
          
          break;   

        case 'cashList':
                                                              //19-09-2023: Allow payment zero input
            if( !validate_int ( data ))_error = "Existen caracteres no válidos";
            if( data.length === 0 )_error = "Campo obligatorio";
            break;          

        case 'parts':
          setParts( data );
          if( !validate_number_non_zero( data ))_error = "Existen caracteres no válidos";
          if( data.length === 0 )_error = "Campo obligatorio";                                                            
          else if( data === '0' )_error = "No puede ser cero";
          setErrorParts( _error );        
          break;  
          
        case 'dateFrom':
          if( data.length === 0 )_error = "Campo obligatorio";
          setErrorDateFrom( _error );        
          break;  
          
          

    }
    console.log( _error )
    return _error;
  }


  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handlePaymentPlaning = event => {
    if(
      validateData(parts,     'parts' )     === '' &&
      validateData(description,  'description' )  === '' &&
      validateData(cash,  'cash' )  === '' &&
      validateData(dateFrom, 'dateFrom' )     === '' 
      )
    {
        setDisable( true );                                              // valid data
        const _parts = [];
        for(let i = 0; i < parts; i++){
          const ob = {
            'id':i,
            'date':'',
            'value':0,
            'error_msg': 'Campo obligatorio',
            'error_msg_date':'Campo obligatorio',
            'type':'VENTAS CUERO',
            'timestamp':0
          };
          _parts.push( ob );
        }
        setDisablePlan( false );
        setPayMentPlanning(_parts);
        update_rest_pay();
    }
  };





  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  const handleChange = event => {
    validateData(event.target.value, event.target.id)
  };




  /*****************************************************************************
   * handleChange: input field change handler
   *****************************************************************************/

  function validatePlanedDate( arr,data, id ){
    var err = '';
    if( data.length === 0 )err = 'Campo obligatorio';
    var pre_date = 0;
    arr.forEach(
      ob =>{
        if( ob.id === Number(id) ){
          if( !err ){
            ob.timestamp =  getUTCfromLocal( data )
              ;
          }
          ob.date = data;
          ob.error_msg_date = err;
        }
      }
    );

    arr.forEach(
      ob =>{
        err = '';
        if( ob.date.length === 0 )err = 'Campo obligatorio';
        if( pre_date > ob.timestamp && !err )err = 'Feha no válida';
        ob.error_msg_date = err;
        pre_date = ob.timestamp;
      }
    );

      return arr;
  }


  const handleListChange = event => {
    //validateData(event.target.value, event.target.id)
    var element_id = event.target.id;
    var element_name = event.target.name;
    var element_value = event.target.value;

    var err =  validateData(element_value, element_name);
    var _arr = [];
    payMentPlanning.forEach( pay =>{
      if( pay.id === Number(element_id)){
        console.log('++')
        switch( element_name ){
          case 'cashList':
            pay.error_msg = err;
            pay.value = element_value;
            break;
          case 'type':
            pay.error_msg_op = err;
            //pay.value = element_value;
            break;
        }
        
      }
      _arr.push( pay );
    });

    if( event.target.type === 'date' )_arr=validatePlanedDate(_arr,element_value,element_id );

    setPayMentPlanning( _arr );
    update_rest_pay();
    //payMentPlanning[element_id].error_msg = err;

    //console.log( _arr )
  };

  function getUTCfromLocal( dateFrom ){
    return (new Date(dateFrom)).getTime();
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  // for radio buttons

  const handleRadioListChange = ev =>{
    console.log( ev )

    var _arr = [];
    payMentPlanning.forEach( pay =>{
      if( pay.id === Number(ev.target.id)){
          pay.type = ev.target.value;       
      }
      _arr.push( pay );
    });
    console.log( _arr )
    setPayMentPlanning( _arr );
    update_rest_pay();
  }
                                                        // summit btn handler
  const handleSummitSales = ev =>{
    var isValid = true;

    payMentPlanning.forEach( pay =>{
      if( 
        pay.error_msg_date || pay.error_msg 
        )isValid = false;
    });

    const ob_sale = {
      'description':description,
      'cash':cash,
      'pays_number':parts,
      'timestamp':getUTCfromLocal(dateFrom),
      'payment':payMentPlanning,
      'client':client_data
    }

    console.log( ob_sale );
    console.log( isValid );
    if(isValid){
                                                        // creates sales
      async function createSale( data )
      {
        NewSale(data).then(
          async res => {
            if(res.status === 'OK'){
              Swal.fire({
                title: 'Venta creada',
                text: "La información de venta se cargó correctamente",
                icon: 'success',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
              });
              await timeout(2000);
              //rstClient();
              navigate('/sales/'+clientEncoded);

            }
            else{


              Swal.fire({
                title: 'Se produjo un error',
                text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
                icon: 'error',
                timer: TIMEOT_SESSION_CLOSES,
                showCancelButton: false,
                showConfirmButton: false                
              });
              await timeout( TIMEOT_SESSION_CLOSES );
              context.logoutUser();
              navigate('/');


            }
            //setDisable( false );                
          }          
        );
      }
      createSale( ob_sale )
                          
    }
  }



    return(
        <Layout>
            <div className="card" style={{width: '65vw'}}>
                <div className="card-header bg-primary">Nueva venta</div> 
                    <div className="card-body col-lg">

                        <div className='d-flex'>
                            <div className="col-2 text-dark">Cliente:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input value={form_name} disabled={true} className="form-control"  type="text" id="name" name="name" />
                                </div>                
                            </div >  
                        
                            <div className="col-2 text-dark">Documento:</div >   
                            <div className="col-4">
                                <div className={classes.inputTextContainer}>
                                    <input  value={form_document} disabled={true} className="form-control"  type="text" id="document" name="document"/>
                                </div>                                
                            </div >   
                        </div>

                        <div className="col-sm"></div > 

                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Resumen de deuda mensual</div >   
                        </div > 

                        <div className="col-lg">
                            <div className={classes.sales_finder_main_container}>
                                <div  style={{width: '60vw',overflowX:'scroll',height:'10vh'}}>
                                    <div className="d-flex">
                                    {
                                        
                                        clientDebts.map( deb => {
                                            return <div style={{width: '20vw'}}  key={deb.month_year} >
                                                <div  className=" text-dark">{deb.month_year}</div>
                                                <div className=" text-secondary">{deb.total}</div>
                                            </div>
                                        })
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                        </div > 

                        <div className="col-sm" style={{height:'1vh'}}></div > 


                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Nueva venta</div >   
                        </div > 

                        <div className="col-lg">
                            <div className={classes.new_sale_container}>
                                <div className='d-flex '>
                                    <div className="col-2 text-dark">
                                        Descripción:   
                                    </div >

                                    <div className="col-4">
                                      <div className={classes.inputTextContainer}>
                                          <input  disabled={disable} className="form-control"  type="text" id="desription" name="desription" onChange={handleChange} onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_description}</div>
                                      </div>                                
                                    </div >  

                                    <div className="col-1 text-dark">
                                           
                                    </div >  

                                    <div className="col-1 text-dark">
                                        Fecha:   
                                    </div >    
                                    
                                    <div className="col-3">
                                            <div>
                                                <input
                                                    className="text-dark"
                                                    type="date"
                                                    onChange={handleChangeDateFrom}
                                                    ref={dateFromInputRef}
                                                    disabled={disable}
                                                />
                                                 <div className="text-danger h6">{error_dateFrom}</div>
                                            </div>
                                    </div > 

                                </div>

                                <div className='d-flex '>
                                    <div className="col-2 text-dark">
                                        Importe:   
                                    </div >

                                    <div className="col-2">
                                      <div className={classes.inputTextContainer}>
                                          <input  disabled={disable} className="form-control"  type="text" id="cash" name="cash" onChange={handleChange} onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_cash}</div>
                                      </div>                                
                                    </div >

                                    <div className="col-1 text-dark">
                                        Cuotas:   
                                    </div >

                                    <div className="col-2">
                                      <div className={classes.inputTextContainer}>
                                          <input  disabled={disable} className="form-control"  type="text" id="parts" name="parts" onChange={handleChange} onBlur={handleChange}/>
                                          <div className="text-danger h6">{error_parts}</div>
                                      </div>                                
                                    </div >


                                    <div className="col-1 text-dark">
                                        Saldo:   
                                    </div >

                                    <div className="col-2">
                                      <div className={classes.inputTextContainer}>
                                          <input disabled={true} value={cash - rest_pay } className="form-control"  type="text" id="rest_pay"/>
                                          <div className="text-danger h6">{error_parts}</div>
                                      </div>                                
                                    </div >                                      

                                    <div className="col-2 text-dark">
                                        <Button  className="btn btn-success"  onClick={handlePaymentPlaning}>Programar</Button>
                                    </div >  

                                </div>
                            </div>    
                        </div >   

                        
                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Agenda de pagos</div >   
                        </div >                          

                        <div className="col-lg">
                            <div className='d-flex'>
                                <div className="col-1 text-dark">
                                    Cuota
                                </div >                              
                                <div className="col-2 text-dark">
                                    Importe
                                </div >      
                                <div className="col-1 text-dark">
                                    
                                </div >                                                                                              
                                <div className="col-3 text-dark">
                                    Vencimiento
                                </div >      
                                <div className="col-1 text-dark"></div >                                                          
                                <div className="col-3 text-dark">
                                    Forma de pago
                                </div >                                                                                              
                            </div >                              
                            <div className={classes.listContainer}>
                            {
                              payMentPlanning.map(
                                part =>{
                                  return <div className='d-flex ' key={part.id}>
                                    <div className="col-1">
                                        <div className={classes.colId}>{part.id+1}</div>
                                    </div >                              
                                    <div className="col-2 text-dark">
                                        <div >
                                          <input  disabled={disablePlan} 
                                            className="form-control"  
                                            type="text" 
                                            id={part.id} 
                                            name="cashList" 
                                            onChange={handleListChange} 
                                            onBlur={handleListChange}/>
                                            <div className="text-danger h6">{payMentPlanning[part.id].error_msg}</div>
                                        </div>  
                                      </div >                                                              
                                      <div className="col-1 text-dark"></div >                          
                                    <div className="col-3 text-dark">
                                      <input
                                            className="text-dark"
                                            type="date"
                                            onChange={handleListChange}
                                            onBlur={handleListChange}
                                            disabled={disablePlan}
                                            id={part.id} 
                                        />                                        
                                      <div className="text-danger h6">{payMentPlanning[part.id].error_msg_date}</div>
                                    </div >      
                                    <div className="col-1 text-dark"></div >                          
                                    <div className="col-3 text-dark">
                                      <div  className={classes.listContainerOptions}>
                                        {
                                          paymentTypes.map(
                                            type =>{
                                              return <div className='text-dark' style={{display:'flex'}} key={type.id}>
                                                <div style={{width:'20px'}}>
                                                  <input 
                                                    disabled={disablePlan} 
                                                    type="radio" 
                                                    value={type.name} 
                                                    name={part.id}
                                                    id={part.id}
                                                    checked = { type.name === payMentPlanning[part.id].type }
                                                    onChange={handleRadioListChange}                                             
                                                     />
                                                </div>
                                                {type.name}
                                                </div>
                                            })
                                        }
                                      </div>
                                    </div >                                        
                                  </div >  
                                }
                              )
                            }
                            </div>
                        </div>                            

                        <div className="col-sm">
                            <div className='d-flex'>
                                <div className="col-3 text-dark"></div>
                                <div className="col-3 text-dark">
                                   <Button disabled={disablePlan}  className="btn btn-success"  onClick={handleSummitSales}>Confirmar operación</Button>
                                </div > 
                                <div className="col-3"><Button  className="btn btn-secondary" onClick={handlerBtn_cancel}>Cancelar</Button></div>
                                <div className="col-3 text-dark"></div>
                            </div >   
                        </div >   
                        <div className="col-sm"></div >   
                        </div >                           
                    </div>                    
                    <div className={classes.spacer}></div>
            
        </Layout>    
    );
}

export default NewSales