/* -------------------------------------------------------------------------------------------------
Clients srvices.
--------------------------------------------------------------------------------------------------*/

import instance from "../Config/axios";
import {Buffer} from 'buffer';
Buffer.from('anything','base64');

/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function getClients(from,to,regs){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        await instance.get(  `/client.php?view`+'&from='+from+'&to='+to+"&regs="+regs
        ,{
            responseEncoding: 'utf8',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
        }
        )
        .then(res => {
            try{console.log( res.headers["content-type"] )}
            catch(e){console.log(e)}
            
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    //console.log( query )
    return query
}

/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function findby(data){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        await instance.get(  `/client.php?findby`+'&data='+data.data+'&source='+data.source)
        .then(res => {
            //console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}



/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function deleteClient(id){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al eliminar el cliente ID: " + id
    };
    try{
        await instance.delete(  '/client.php?delete_client'+'&id='+id)
        .then(res => {
            //console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}




/* -------------------------------------------------------------------------------------------------
getClients: gets clients with specified parameters.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function findFrom(data){
    
    var query = {
        status:'error',
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        let req = `/client.php?`;
        if( data.isUpper )req = req + 'viewNext';
        else req = req + 'viewPrev';

        await instance.get(  req+'&from='+data.from )
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}

/* -------------------------------------------------------------------------------------------------
newClient: creates new client.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function newClient(data){
    
    var query = {
        status:'error',
        close_session:true,
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        let req = `/client.php?newclient`;
        await instance.post(  req, data )
        .then(res => {
            console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            console.log( error.response.status )
            if( error.response.status === 400 )query.close_session = false;
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}



/* -------------------------------------------------------------------------------------------------
newClient: creates new client.
--------------------------------------------------------------------------------------------------*/
                                                                                
export async function changeClient(data){
    
    var query = {
        status:'error',
        close_session:true,
        msg:"Se produjo un error al obtener la lista de clientes."
    };
    try{
        let req = `/client.php?changeclient`;
        await instance.post(  req, data )
        .then(res => {
            console.log( res )
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.data;
            }
        }).catch(function (error) {
            if( error.response.status === 400 )query.close_session = false;
            if( error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}