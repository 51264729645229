import React,{ useEffect, useState,useContext} from 'react';
import Layout from "../components/Layout";
import {getClients,findby,findFrom,deleteClient} from "../Services/ClientServices";
import AuthContext from '../Context/AuthContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import classes from "../components/Clients.module.scss";
import ClientsFinder from "../components/ClientsFinder";
import {StringToBinary, StringFromBinary} from "../utils/Base64Utils"
// coments
import icon_view_sales from '../assets/images/view-sales.png';
import icon_delete from '../assets/images/delete-icon.png';
import icon_profile from '../assets/images/profile.png';


const TIMEOT_SESSION_CLOSES = 3000;
const CLIENTS_REGS = 50;
var client_from = 0;
var client_to = 100;


function Clients (){
  const [clients,setClients] = useState([]);
  const find_user_data_req = {
    'data':null,
    'source':null
  };

  const find_clients_page = {
    'from':null,
    'isUpper':null
  };

  const context = useContext(AuthContext);
  const navigate = useNavigate();
  

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

                                                        // update clients list (from child component)
                                                        // expects json {data:[data],source:[source]}
  function updateClientList( data )
  {
    //console.log( data );
    if( !data.data && !data.source )request();          // refreshes full clients list
    else{
      find_user_data_req.data = data.data;
      find_user_data_req.source = data.source;
      findClient();
    }
  }

  const findClient = async() => {
    console.log( find_user_data_req )
    findby( find_user_data_req ).then(
      async res => {
        console.log( res );            
        if(res.status === 'OK')
        {
          console.log( res.msg );
          if( res.msg.length === 0)setClients( res.msg );
          else setClients( ...res.msg );
        }
        else{
          Swal.fire({
            title: 'Se produjo un error',
            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
            icon: 'error',
            timer: TIMEOT_SESSION_CLOSES,
            showCancelButton: false,
            showConfirmButton: false                
          });
          await timeout( TIMEOT_SESSION_CLOSES );
          context.logoutUser();
          navigate('/');
        }
      }
    );
  }

  function clientsNextPage( data )
  {
    //console.log( data );
    if( clients.length !== 0){
      if(data.isUpper ){
          console.log( clients[clients.length-1].id_cliente );
          find_clients_page.from = 
                clients[clients.length-1].id_cliente;        // find last id
      }else{
        console.log( clients[0].id_cliente );
        find_clients_page.from = clients[0].id_cliente;
      } 
        find_clients_page.isUpper = data.isUpper;          // sets up or down search  
        findClientFrom();
                
    }else request();

  }


  const findClientFrom = async() => {
    console.log( find_clients_page )
    findFrom( find_clients_page ).then(
      async res => {
        console.log( res );            
        if(res.status === 'OK')
        {
          console.log( res.msg );
          if( res.msg.length === 0)setClients( res.msg );
          else setClients( ...res.msg );
        }
        else{
          Swal.fire({
            title: 'Se produjo un error',
            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
            icon: 'error',
            timer: TIMEOT_SESSION_CLOSES,
            showCancelButton: false,
            showConfirmButton: false                
          });
          await timeout( TIMEOT_SESSION_CLOSES );
          context.logoutUser();
          navigate('/');
        }
      }
    );
  }


  const request = async() => {
    const _ob = {
      'from':0,
      'isUpper':true
    }
    findFrom( _ob ).then(
      async res => {
        console.log( res );            
        if(res.status === 'OK')
        {
          console.log( res.msg );
          setClients( ...res.msg );
        }
        else{
          Swal.fire({
            title: 'Se produjo un error',
            text: 'Se cerrará la sesión actual en ' + TIMEOT_SESSION_CLOSES/1000 + ' segundos',
            icon: 'error',
            timer: TIMEOT_SESSION_CLOSES,
            showCancelButton: false,
            showConfirmButton: false                
          });
          await timeout( TIMEOT_SESSION_CLOSES );
          context.logoutUser();
          navigate('/');
        }
      }
    );
  }


  useEffect(
    () => {
      request();
    },[]
  );

  const handleView = event =>{
    var client = null;
    clients.forEach( _cli =>{
      if( _cli.id_cliente === event.target.id )client = _cli;
    });
    if( client ){
      const client_str = JSON.stringify( client );
      navigate( '/viewclient/'+ StringToBinary( client_str ));
      
    }

  }

  const handleViewSales = event => {
    var client = null;
    clients.forEach( _cli =>{
      if( _cli.id_cliente === event.target.id )client = _cli;
    });
    if( client ){
      const client_str = JSON.stringify( client );
      navigate( '/sales/'+ StringToBinary( client_str ));
      
    }
  }

 const handleDelete = event =>{
  console.log( event.target.id );
  Swal.fire({
    title: 'Confirmar eliminación',
    text: 'Desea eliminar el cliente ID: ' + event.target.id + '?',
    icon: 'warning',
    confirmButtonText: 'Sí, eliminarlo!',
    cancelButtonText: "No, cancelar",
    showCancelButton: true,
  }).then(
    function(isConfirm) 
    {
      //console.log(isConfirm.isConfirmed)
      if(isConfirm.isConfirmed){
        console.log('delete in process.. ID: ' + event.target.id)
        deleteClient( event.target.id ).then(
          async res => {
            
            if(res.status === 'OK')
            {
              Swal.fire({
                title: 'Cliente eliminado',
                text: "El cliente se elimino correctamente",
                icon: 'success',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
              });
              await timeout(2000);
              request()
            }
            else{
              Swal.fire({
                title: 'Se produjo un error',
                text: 'Se produjo un error al eliminar el cliente: ' + res.msg,
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showConfirmButton: true,
              });
              await timeout( TIMEOT_SESSION_CLOSES );
            }
          }
        );
      }
    }
  );

} 



  return(
    <Layout>
      <ClientsFinder updateClients={ updateClientList } clientsNextPage ={ clientsNextPage}/>
      <div className={classes.ClientsHeadList}>
        <div className={classes.ClientsHeadItems_1}>Id</div>
        <div className={classes.ClientsHeadItems_2}>Apellido y nombre</div>
        <div className={classes.ClientsHeadItems_3}>DNI</div>
        <div className={classes.ClientsHeadItems_4}>Opción</div>
      </div>
       <div className={classes.ClientsBodyList}>
       
        {
            clients.map(
              client=>
              <div key={client.id_cliente} className={classes.ClientResultLine}>
                <div className={classes.ClientsHeadItems_1}>{client.id_cliente}</div>
                <div className={classes.ClientsHeadItems_2}>{client.apellidonombre}</div>
                  <div className={classes.ClientsHeadItems_3}>{client.dni}</div>
                  <div className={classes.ClientsOptions}>
                      <div>
                        <div title="Datos del cliente">
                          <img onClick={handleView} id={client.id_cliente} className={classes.logo} src={icon_profile}/>
                        </div>
                      </div>
                      <div>
                        <div title="Información de ventas">
                          <img className={classes.logo} onClick={handleViewSales} id={client.id_cliente} src={icon_view_sales}/>
                        </div>
                      </div>
                      <div>
                        <div title="Eliminar cliente">
                          <img onClick={handleDelete} id={client.id_cliente} className={classes.logo} src={icon_delete}/>
                        </div>
                      </div>
                  </div>
              </div>
            )
          
        }
       
       </div>

    </Layout>
  )
}

export default Clients