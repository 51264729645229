import React,{useState} from "react";
import AuthContext from "./AuthContext";

function AuthProvider(props){
    const [userLogin,setUserLogin] = useState(localStorage.getItem("login")||false); 
    const [userInfo,setUserInfo]   = useState(JSON.parse(localStorage.getItem("userInfo"))||{});
   
                                                                        // proceso de login: almacena en local storage
                                                                        // y en context
    const loginUser = (userInfo)=>{
        //getUserInfo();
        setUserLogin(true);
        localStorage.setItem("login",true);
        setUserInfo(userInfo);
        localStorage.setItem("userInfo",JSON.stringify(userInfo));
    }
                                                                        // proceso logout: reset de almacenamiento local
                                                                        // y context
    const logoutUser = ()=>{
        setUserLogin(false);
        localStorage.removeItem("login");
        localStorage.removeItem("userInfo");
        //window.location.reload();                                       // (FE06112023-1)
    }

                                                                        // funciones y datos externos
    return(
        <AuthContext.Provider
            value={{
                userLogin,
                loginUser,
                logoutUser,
                userInfo
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;