import instance from "../Config/axios";

/* -------------------------------------------------------------------------------------------------
loginUser: logins an user.
--------------------------------------------------------------------------------------------------*/
                                                                                // login user
export async function sendMail(user){
    
    var query = {
        status:'error',
        msg:"Credenciales no válidas"
    };
    try{
        await instance.post(  `/operations.php?send`, user)
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.message;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}

/* -------------------------------------------------------------------------------------------------
presentation.
--------------------------------------------------------------------------------------------------*/
                                                                                // login user
export async function presentation( data ){
    
    var query = {
        status:'error',
        msg:"Credenciales no válidas"
    };
    try{
        await instance.get(  `/operations.php?presentation&from=`+data.from
                            +'&to='+data.to
                            +'&file_name_ventas='+data.file_name_ventas
                            +'&file_name_ventas_cuero='+data.file_name_ventas_cuero)
        .then(res => {
            if( res.status === 200  ){
                query.status = 'OK';
                query.msg = res.data.message;
            }
        }).catch(function (error) {
            if(error.response.data.message)query.msg = error.response.data.message;
          });
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}


/* -------------------------------------------------------------------------------------------------
updateMailCredentials.
--------------------------------------------------------------------------------------------------*/
                                                                                // login user
export async function updateMailCredentials(){
    
    var query = {
        status:'OK',
        msg:"Credenciales no válidas"
    };
    try{
        //window.location.href = 'http://127.0.0.1/services/api/operations.php?update_mailer';
        window.location.href = 'https://www.henrycotacto.com/services/api/operations.php?update_mailer';
        
    }catch(e){
        query.msg = "Se produjo un error en la solicitud.";
    }
    console.log( query )
    return query
}