export function validate_int( data ){
    const regex = /^[0-9.\-]+$/i;
    return data.match( regex );
}

export function validate_int_greater_zero( data ){
    const regex = /^[1-9][0-9]*$/i;
    return data.match( regex );
}

export function validate_number_non_zero( data ){
    const regex = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i;
    return data.match( regex );
}


export function alphabetic_with_space( data ){
    const regex = /^[a-zA-Z ñÑ.\-]+$/i;
    return data.match( regex );
}

export function alphabetic_with_space_and_comma( data ){
    const regex = /^[a-zA-Z ñÑ,.\-]+$/i;
    return data.match( regex );
}

export function valid_len( data, min, max ){
    let ret = false;
    if( data.length > min && data.length < max) ret = true;
    return ret;
}

export function validate_email( data ){
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return data.match( regex );
}

export function validate_alphanumeric_spaces_symbols( data ){
    const regex = /^[0-9a-zA-Z ñÑ().\-]+$/i;
    return data.match( regex );
}

export function validate_alphanumeric_spaces_more_symbols( data ){
    const regex = /^[0-9a-zA-Z ñÑ(),°.áéíóúÁÉÍÓÚ\-]+$/i;
    return data.match( regex );
}

export function validate_file_name( data ){
    const regex = /^[0-9a-zA-Z._\-]+$/i;
    return data.match( regex );
}

export function validate_alphanumeric( data ){
    const regex = /^[0-9a-zA-Z]+$/i;
    return data.match( regex );
}

export function validate_credentials( data ){
    const regex = /^[0-9a-zA-Z._\-]+$/i;
    return data.match( regex );
}

export function validate_uri_secure( data ){
    const regex = /^https:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/i;
    return data.match( regex );
}

export function validate_uri_no_secure( data ){
    const regex = /^http:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/i;
    return data.match( regex );
}

