/* Defaulta Not Found */
import React from 'react';

function NotFound() {
  
    return (
      <div>
        404 Page not Found
      </div>
    );  
}

export default NotFound;
