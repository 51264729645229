import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
  } from "react-router-dom"

import Clients from '../pages/Clients';
import Login from '../pages/Login';
import AuthContext from '../Context/AuthContext';
import Menu from '../components/Menu'
import NotFound from '../pages/NotFound';
import Container from 'react-bootstrap/Container';

import EditClient from '../crud/clients/EditClient';
import NewClient from '../crud/clients/newClient';
import Sales from '../pages/Sales';
import NewSale from '../crud/sales/NewSale';
import EditSales from '../crud/sales/EditSale';
import SalesViewer from "../pages/SalesViewer";
import Configurations from "../pages/config";
import Files from "../pages/Files";

function Public() {
    return (
        <Router basename="/">
             <Menu />
            <Container>
            <AuthContext.Consumer>{ context=>            
                <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/" element={<Login />}/>
                {
                     context.userLogin && 
                     <>
                        <Route path="/clients" element={<Clients />}/>
                        <Route path="/newclient" element={<NewClient />}/>
                        <Route path="/viewclient/:client" element={<EditClient />}/>
                        <Route path="/sales/:client" element={<Sales />}/>
                        <Route path="/sales/new/:client" element={<NewSale />}/>
                        <Route path="/sales/edit/:sale" element={<EditSales />}/>
                        <Route path="/viewsales" element={<SalesViewer />}/>
                        <Route path="/config" element={<Configurations />}/>
                        <Route path="/files" element={<Files />}/>
                     </>
                }
                {
                    !context.userLogin && <Route path="*" element={<Login />} />
                }
                </Routes>
            }</AuthContext.Consumer>  
            </Container>   
        </Router>
    );
}

export default Public;