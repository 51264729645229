import React,{ useRef,useEffect, useState,useContext} from 'react';
import Layout from "../components/Layout";
import classes from "../components/Sales.module.scss";
import { getConfig, UpdateConfig} from "../Services/ConfigurationsService";
import { updateMailCredentials } from "../Services/Operations";
import Button from "react-bootstrap/Button";
import { 
    validate_email,
    validate_alphanumeric,
    validate_int,
    validate_alphanumeric_spaces_more_symbols,
    validate_uri_no_secure,
    validate_uri_secure,
    validate_credentials
  } from '../utils/validator';

import Swal from 'sweetalert2';
import AuthContext from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

import {useParams} from "react-router-dom";

function Configurations(){
                                                            // gets client data
    const context = useContext(AuthContext);
    const TIMEOT_SESSION_CLOSES = 3000;
    const navigate = useNavigate();
    const [dateFrom, setDateFrom] = useState('');
    
    const [disable, setDisable] = useState(false);
    const [form_email, set_form_email] = useState('');
                                                            // FE02062024: Client id and Redirect Uri fields
    const [form_client_id, set_form_client_id] = useState('');
    const [form_redirect_uri, set_form_redirect_uri] = useState('');

    const [ form_api_key, set_form_api_key ] = useState('');
    const [form_enterprise, set_form_enterprise] = useState('');
    const [ form_cuit, set_form_cuit ] = useState('');
                                                            // FE02062024: Client id and Redirect Uri fields errors
    const [ apiKeyError, set_apiKeyError ] = useState('');
    const [ emailError, set_emailError ] = useState('');

    const [ clientIdError, set_clientIdError ] = useState('');
    const [ redirectURIError, set_redirectURIError ] = useState('');    

    const [ enterpriseError, set_enterpriseError ] = useState('');
    const [ cuitError, set_cuitError ] = useState('');
    

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }    
    
    function validate_inputs(){
        var ret = true;
        
        if( !validate_email(form_email)  ){
            set_emailError('Email no válido');
            ret = false;
        }
        else set_emailError('');

        if( !validate_credentials(form_api_key)  ){
            set_apiKeyError('Api Key no válida');
            ret = false;
        }
        else set_apiKeyError('');

                                                                // FE02062024: client id and uri validations

        if( !(validate_uri_no_secure(form_redirect_uri) || validate_uri_secure(form_redirect_uri)) ){
            set_redirectURIError('URI no válida');
            ret = false;
        }
        else set_redirectURIError('');

        if( !validate_credentials(form_client_id)  ){
            set_clientIdError('Cliente no valido');
            ret = false;
        }
        else set_clientIdError('');


        if( !validate_alphanumeric_spaces_more_symbols(form_enterprise)  ){
            set_enterpriseError('Nombre de empresa no válido');
            ret = false;
        }
        else set_enterpriseError('');

        if( !validate_int(form_cuit)  ){
            set_cuitError('CUIT no válido');
            ret = false;
        }
        else set_cuitError('');


        return ret;
    }

    const handleInputChange = ev =>{
        console.log( ev.target );
        switch( ev.target.name ){
                                                            // FE02062024: Client id and Redirect Uri fields                                                            
            case 'client_id':
                set_form_client_id( ev.target.value );
                if( !validate_credentials(ev.target.value)  ){
                    set_clientIdError('Cliente no valido');
                }
                else set_clientIdError('');
                break;

            case 'redirect_uri':
                set_form_redirect_uri( ev.target.value );
                if( !(validate_uri_no_secure(ev.target.value) || validate_uri_secure(ev.target.value))  ){
                    set_redirectURIError('URI no válida');
                }
                else set_redirectURIError('');
                break;                

            case 'email':
                set_form_email( ev.target.value );
                if( !validate_email(ev.target.value)  ){
                    set_emailError('Email no válido');
                }
                else set_emailError('');
                break;

            case 'apikey':
                set_form_api_key( ev.target.value );
                if( !validate_credentials(ev.target.value)  ){
                    set_apiKeyError('Api Key no válida');
                }
                else set_apiKeyError('');
                break;
            case 'cuit':
                set_form_cuit( ev.target.value );
                if( !validate_int(ev.target.value)  ){
                    set_cuitError('CUIT no válido');
                }
                else set_cuitError('');
                break;
            case 'enterprise':
                set_form_enterprise( ev.target.value );
                if( !validate_alphanumeric_spaces_more_symbols(ev.target.value)  ){
                    set_enterpriseError('Nombre de empresa no válido');
                }
                else set_enterpriseError('');
                break;
        }
    }

    function request(){
        getConfig().then(

            res => {
                console.log( res );            
                if(res.status === 'OK')
                {
                    try{
                        res.msg.forEach(
                            row =>{
                                if(row.config_name === 'email')set_form_email( row.config_value );
                                if(row.config_name === 'api_key')set_form_api_key( row.config_value );

                                                                // FE02062024: Client id and Redirect Uri fields
                                if(row.config_name === 'client_id')set_form_client_id( row.config_value );
                                if(row.config_name === 'redirect_uri')set_form_redirect_uri( row.config_value );

                                if(row.config_name === 'cuit')set_form_cuit( row.config_value );
                                if(row.config_name === 'enterprise')set_form_enterprise( row.config_value );
                            }
                        );                        
                    }catch(e){
                        console.log(e);
                    }

                  console.log( res.msg );
                  
                }else{
                    
                        Swal.fire({
                          title: 'Se produjo un error',
                          text: 'Se cerrará la sesión actual',
                          icon: 'error',
                          timer: TIMEOT_SESSION_CLOSES,
                          showCancelButton: false,
                          showConfirmButton: false                
                        });
                       
                        context.logoutUser();
                        navigate('/');
                      
                }

        });
    }

                                                                    // (FE02062024): update token btn handler

    function handleBtnClickUpdateToken(){
        updateMailCredentials().then(async res =>{
            console.log( res )
            if(res.status !== 'OK'){
                Swal.fire({
                    title: 'Se produjo un error',
                    text: 'Se cerrará la sesión actual',
                    icon: 'error',
                    timer: TIMEOT_SESSION_CLOSES,
                    showCancelButton: false,
                    showConfirmButton: false                
                  });
              }
        }); 
    }


    function handleBtnClick (){
        if( validate_inputs() ){
            setDisable( true );
            const _ob = {
                'email':form_email,
                'api_key':form_api_key,
                'cuit':form_cuit,
                'client_id':form_client_id,
                'redirect_uri':form_redirect_uri,
                'enterprise':form_enterprise
            }
            UpdateConfig(_ob ).then(async res =>{
                console.log( res )
                if(res.status === 'OK'){
                    Swal.fire({
                      title: 'Configuración actualizada',
                      text: "Se actualizaron las configuraciones de email y api key",
                      icon: 'success',
                      timer: 2000,
                      showCancelButton: false,
                      showConfirmButton: false
                    });
                    await timeout(2000);
                    navigate('/clients');
                    setDisable( false );
                  }
                  else{
                    setDisable( false );
                    
                    Swal.fire({
                        title: 'Se produjo un error',
                        text: 'Se cerrará la sesión actual',
                        icon: 'error',
                        timer: TIMEOT_SESSION_CLOSES,
                        showCancelButton: false,
                        showConfirmButton: false                
                      });
                     
                      context.logoutUser();
                      navigate('/');
                  }
            });   
        }
    }


    useEffect(
        () => {
          request();
        },[]
      );

    return(
        <Layout>
            <div className="card" style={{width: '65vw'}}>
                <div className="card-header bg-primary">Ajustes del sistema</div> 
                    <div className="card-body col-lg">

                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Configuración de informacion fiscal</div >   
                        </div >                         
                        <div className={classes.spacer}></div>
                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Razón social:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_7}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_enterprise} disabled={disable} className="form-control"  type="text" id="enterprise" name="enterprise" />
                                <div className="text-danger h6">{enterpriseError}</div>
                            </div >   
                            <div className={classes.ClientsHeadItems_2}>CUIT:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_6}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_cuit} disabled={disable} className="form-control"  type="text" id="cuit" name="cuit" />
                                <div className="text-danger h6">{cuitError}</div>
                            </div >   
                            <div className="col-sm"></div > 

                        </div >   
                        <div className={classes.spacer}></div>


                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Configuración de la cuenta de correo para envío de emails</div >   
                        </div >                         

                        <div className={classes.spacer}></div>
                        <div className='d-flex'>
                            <div className="col-lg text-dark bg-secondary">Google Gmail:</div >   
                        </div >                         

                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Cuenta:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_7}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_email} disabled={disable} className="form-control"  type="email" id="email" name="email" />
                                <div className="text-danger h6">{emailError}</div>
                            </div >   
                            <div className={classes.ClientsHeadItems_2}>Api key:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_7}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_api_key} disabled={disable} className="form-control"  type="text" id="apikey" name="apikey" />
                                <div className="text-danger h6">{apiKeyError}</div>
                            </div >

                            <div className="col-sm">
                            </div >   
                        </div > 

                        

                        <div className='d-flex'>
                            <div className={classes.ClientsHeadItems_2}>Client Id:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_7}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_client_id} disabled={disable} className="form-control"  type="text" id="client_id" name="client_id" />
                                <div className="text-danger h6">{clientIdError}</div>
                            </div >   
                            <div className={classes.ClientsHeadItems_2}>Redirect URI:</div >   
                            <div className="col-1"></div>   
                            <div className={classes.ClientsHeadItems_7}>
                                <input onBlur={validate_inputs} onChange={handleInputChange} value={form_redirect_uri} disabled={disable} className="form-control"  type="text" id="redirect_uri" name="redirect_uri" />
                                <div className="text-danger h6">{redirectURIError}</div>
                            </div >

                            <div className="col-sm">
                            </div >   
                        </div > 


                        <div className={classes.spacer}></div>
                        <div className='d-flex'>
                                    <div className="col-sm"><Button onClick={handleBtnClick} className="btn btn-primary" >Cambiar datos de configuración</Button></div>

                                    <div className="col-sm"><Button onClick={handleBtnClickUpdateToken} className="btn btn-primary" >Actualizar Credenciales de Gmail</Button></div>
                        </div >   
                    
                </div >                         
            </div >                             
        </Layout>    
    );
}

export default Configurations